import { useContext, useEffect, useRef, useState } from "react";

import { i18n } from "../../../translate/i18n";

import { AuthContext } from "../../../context/Auth/AuthContext";
import { Grid, Button, Box, Tabs, Tab, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Divider } from "@material-ui/core";
import PropTypes from 'prop-types';
import OriginTab from "../Tabs/Origin";
import ProductAndServiceTab from "../Tabs/ProductAndService";
import { makeStyles } from "@material-ui/core";
import StatusTab from "../Tabs/Status";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`customerConfigurations-tabpanel-${index}`}
			aria-labelledby={`customerConfigurations-${index}`}
			component="div"
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</Box>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `customerConfiguration-tab-${index}`,
		'aria-controls': `customerConfiguration-tabpanel-${index}`,
	};
}

const CustomerRelationshipConfigurationModal = ({ open, onClose }) => {
	const { user: loggedInUser } = useContext(AuthContext);

	const classes = useStyles();

	const initialState = {
		origins: [],
	};

	const [configuration, setConfiguration] = useState(initialState);
	const [value, setValue] = useState(0);

	const handleClose = (event, reason) => {
		if (reason == 'backdropClick') {
			event.preventDefault();
			return;
		}

		onClose();
		setValue(0);
		setConfiguration(initialState);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open == true ? true : false}
				onClose={(event, reason) => handleClose(event, reason)}
				scroll="paper"
				fullWidth
				maxWidth="md"
				disableEscapeKeyDown={true}
			>
				<DialogTitle>
					{i18n.t('customerRelationship.configuration.title')}
				</DialogTitle>
				<DialogContent style={{ padding: "0 10px", height: "70vh" }}>
					{/* cabeçalhos */}
					<Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
						<Tab label={i18n.t('customerRelationship.configuration.origin.title')} {...a11yProps(0)} />
						<Tab label={i18n.t('customerRelationship.configuration.productAndService.title')} {...a11yProps(1)} />
						<Tab label={i18n.t('customerRelationship.configuration.status.title')} {...a11yProps(2)} />
					</Tabs>
					<TabPanel value={value} index={0}>
						<OriginTab />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<ProductAndServiceTab />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<StatusTab />
					</TabPanel>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
					>
						{i18n.t("buttons.close")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CustomerRelationshipConfigurationModal;