import { useEffect, useState } from "react";

import { Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	
	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	}
	
}));




const CompanyPaymentModal = ({ open, onClose, companyId }) => {
	const classes = useStyles();
	const initialState = {
		name: "",
		cnpj: "",
		maxWhatsapps: "",
		maxUsers: "",
		maxCampaign: "",
		status: true,
		faturamentoMensal: "",
		dataInicioFaturamento: "",
		cicloFaturamento: "",
		teste: "",
		inicioDoTeste: "",
		terminoDoTeste: "",
		formaPagamento: "",
		valorUnicoSetup: "",
		valorMensalPorUsuario: "",
		valorMensalPorConexao: "",
		valorMensalBot: "",
		valorSetupBot: "",
		valorMensalOutros: "",
		usersManagement: true
	};

	const [successCreate, setSuccessCreate] = useState(false)
	const [successMsgCreate, setMsgSuccessCreate] = useState({})
	const [company, setCompany] = useState(initialState);
	const [valor, setValor] = useState('')
	useEffect(() => {
		const fetchUser = async () => {
			if (!companyId) {
				setValor("");
				setSuccessCreate("")
				return;
			};
			try {
				setValor("");
				const { data } = await api.get(`/companyPayment/${companyId}`);
				var array = {
					"cnpj": setValor(data.cnpj),
					"monthlyBilling":data.monthlyBilling,
					"initBilling":data.initBilling,
					"billingCicle":data.billingCicle,
					"test":data.test,
					"testInit":data.testInit,
					"endTest":data.endTest,
					"paymentOption":data.paymentOption,
					"setupValue":data.setupValue,
					"monthlyValue":data.monthlyValue,
					"maxWhatsapps": data.maxWhatsapps,
					"maxUsers": data.maxUsers,
					"maxCampaign": data.maxCampaign,
					"id": data.id,
					"status": data.status,		
					"name": data.name,
					"usersManagement": data.usersManagement
				}
				setCompany(prevState => {
					return { ...prevState, ...array };
				});
			} catch (err) {
				console.error({err});
				toastError(err);
			}
		};

		fetchUser();
	}, [companyId, open]);

	const handleClose = () => {
		onClose();
		setCompany({...initialState});
	};

	const handleSaveCompany = async values => {
		const CompanyData = { ...values };

		try {
			if (companyId) {
				await api.put(`/company/${companyId}`, CompanyData);
				toast.success('Atualizado com sucesso!');
				handleClose();
			} else {
				await api.post("/company", CompanyData);
		
			}
			// handleClose();
		} catch (err) {
			console.error({err});
			toastError(err);
		}

	};
	
	function openDatePickerStatTest(input) {
		var inputDate = document.getElementById(input)
		if (!inputDate.disabled) {
			inputDate.showPicker()
		}
	}
		function openDatePickerFinishTest(input) {
		var inputDate = document.getElementById(input)
		if (!inputDate.disabled) {
			inputDate.showPicker()
		}
	}

		function openDatePickerStartInvoicing(input) {
		var inputDate = document.getElementById(input)
		if (!inputDate.disabled) {
			inputDate.showPicker()
		}
	}
	

	const periodoTeste = ['NÃO','7 DIAS', '14 DIAS','21DIAS', '30 DIAS'];

	const cicloFaturamentoSelect = ['1', '2', '3', '4', '5',
									'6', '7', '8', '9', '10',
									'11', '12', '13', '14', '15',
									'16', '17','18', '19', '20',
									'21','22 ', '23','24', '25',
									'26','27 ', '28','29', '30',
									'31'];

	const pagamento = ['CARTÃO DE CRÉDITO', 'PIX', 'DINHEIRO', 'TRANSFERÊNCIA', 'OUTROS'];


	const mask = (v) => {
		v = v.replace(/\D/g, "")
		v = v.replace(/^(\d{2})(\d)/, "$1.$2")
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
		v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
		v = v.replace(/(\d{4})(\d)/, "$1-$2")
		return v.substring(0, 18)
	}

	function handleChangeMask(event) {
		const { value } = event.target

		setValor(mask(value))
	}
	return (
		<div className={classes.root}>
			<Dialog
				open={open == true ? true : false}
				onClose={handleClose}
				maxWidth="md"
				fullWidth

				scroll="paper"

			>
				<DialogTitle id="form-dialog-title">
					{companyId
						? `${'Editar Pagamentos'}`
						: `${'Pagamentos'}`}
				</DialogTitle>
				<Formik
					initialValues={company}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							var array = {
								"cnpj": valor,
								"monthlyBilling":values.monthlyBilling,
								"initBilling":values.initBilling,
								"billingCicle":values.billingCicle,
								"test":values.test,
								"testInit":values.testInit,
								"endTest":values.endTest,
								"paymentOption":values.paymentOption,
								"setupValue":values.setupValue,
								"monthlyValue":values.monthlyValue,
								"maxWhatsapps": values.maxWhatsapps,
								"maxUsers": values.maxUsers,
								"maxCampaign": values.maxCampaign,
								"id": values.id,
								"status": values.status,		
								"name": values.name,
								"usersManagement": values.usersManagement
							}
							handleSaveCompany(array);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<>

							{successCreate ?
								<>
									<DialogContent dividers>
										<div>
											
										</div>
									</DialogContent>
									<DialogActions dividers>
										<Button
											onClick={handleClose}
											color="primary"
											variant="outlined"
										>
											{'FINALIZAR'}
										</Button>
									</DialogActions>
								</>
								:
								<Form style={{ padding: "10px"}}>

									<DialogContent dividers>
										<div className="form-row">
											<Field
												type="hidden"
												name="adminId"
											/>
											<div class="form-group col-md-6">
												<label>Razão social</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Razão social'}
													type="text"
													name="name"
													required

												/>
											</div>
											<div class="form-group col-md-6">
												<label>CNPJ</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'00.000.000/0000-00'}
													maxlength="18"
													onChange={handleChangeMask} value={valor}
													type="text"
													name="cnpj"
													required

												/>
											</div>
											<div class="form-group col-md-6">
												<label>N° Whatsapps</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'N°'}
													type="number"
													name="maxWhatsapps"
													required
													min="1"
												/>
											</div>

											<div class="form-group col-md-3">
												<label>N° Usuários</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'N°'}
													type="number"
													name="maxUsers"
													min="1"
													required

												/>
											</div>
											<div class="form-group col-md-3">
												<label>N° Campanhas</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'N°'}
													type="number"
													min="0"
													name="maxCampaign"
												/>
											</div>
											<div class="form-group col-md-6">
												<label>Faturamento mensal:</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'R$'}
													type="number"
													pattern="[0-9]+([\\,\\.]?[0-9]+)?"
													name="monthlyBilling"
												/>
											</div>
											
											<div class="form-group col-md-3">
												<label>Data inicio do faturamento</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													onClick={e => { openDatePickerStartInvoicing('dataInicioFaturamento') }}
													className='form-control'
													placeholder={'(DD/MM/AAAA)'}
													id="dataInicioFaturamento"
													type="date"
													name="initBilling"
												/>
											</div>
											<div class="form-group col-md-3">
											<label>Data ciclo de faturamento</label>
											<Field
												as={Select}
												native
												style={{
													borderColor: '#c4c4c4',
													borderRadius: '3px'
													
												}}
												className='form-control'
												placeholder={'DD'}
												type="number"
												name="billingCicle"
												
											>
												{cicloFaturamentoSelect.map((cicloFaturamentoSelect) => (
													<option
													className='fw-bolder text-hover-primary d-block'
													style={{
														cursor: 'pointer'
													}}
													key={cicloFaturamentoSelect}
													value={cicloFaturamentoSelect}
													>
													{cicloFaturamentoSelect}
													</option>
												))}
												</Field>
											
											</div>

											
											<div class="form-group col-md-6">
												<label>Periodo teste</label>
												<Field
													native
													as={Select}
												style={{
													padding:'6px',
													borderColor: '#c4c4c4',
													borderRadius: '3px'
												}}
												className='form-control'
												type="text" 
												name="test"
												>
												{periodoTeste.map((periodoTeste) => (
													<option
													className='fw-bolder text-hover-primary d-block'
													style={{
														cursor: 'pointer',}} 
														key={periodoTeste} value={periodoTeste}>{periodoTeste}</option>
												))}
												</Field>
											</div>
											<div class="form-group col-md-3">
												<label>Inicio periodo teste</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													onClick={e => { openDatePickerStatTest('inicioDoTeste') }}
													className='form-control'
													id="inicioDoTeste"
													type="date"
													placeholder={'(DD/MM/AAAA)'} 
													name="testInit"
												/>
												</div>
											<div class="form-group col-md-3">
												<label>Término periodo teste</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													onClick={e => { openDatePickerFinishTest('terminoDoTeste') }}
													className='form-control'
													placeholder={'(DD/MM/AAAA)'}
													id="terminoDoTeste"
													type="date"
													name="endTest"
												/>
											</div>
											<div class="form-group col-md-6">
												<label>Forma de pagamento</label>
												<Field
												as={Select}
												native
												style={{
													padding:'6px',
													borderColor: '#c4c4c4',
													borderRadius: '3px'
												}}
												className='form-control'
												type="text" 
												name="paymentOption"
												>
												{pagamento.map((pagamento) => (
													<option
													className='fw-bolder text-hover-primary d-block '
													style={{
														cursor: 'pointer',}} 
														key={pagamento} value={pagamento}>{pagamento}</option>
												))}
												</Field>
											</div>
											<div class="form-group col-md-3">
												<label>Valor setup:</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'R$'}
													type="number"
													pattern="[0-9]+([\\,\\.]?[0-9]+)?"
													name="setupValue"
												/>
											</div>
											<div class="form-group col-md-3">
												<label>Valor mensal</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'R$'}
													type="number"
													pattern="[0-9]+([\\,\\.]?[0-9]+)?"
													name="monthlyValue"
												/>
											</div>
										</div>
										<div className={classes.multFieldLine}>
											<FormControl
												variant="outlined"
												className={classes.formControl}
												margin="dense"
											>
											</FormControl>
										</div>
									</DialogContent>
									<DialogActions>
										<Button
											onClick={handleClose}
											color="secondary"
											disabled={isSubmitting}
											variant="outlined"
										>
											{'cancelar'}
										</Button>
										<Button
											type="submit"
											color="primary"
											disabled={isSubmitting}
											variant="contained"
											className={classes.btnWrapper}
										>
											{companyId
												? `${'Salvar'}`
												: `${'Salvar'}`}
											{isSubmitting && (
												<CircularProgress
													size={24}
													className={classes.buttonProgress}
												/>
											)}
										</Button>
									</DialogActions>
								</Form>
							}
						</>
					)}
				</Formik>

			</Dialog>
		</div>
	);
};

export default CompanyPaymentModal;
