import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    FormControlLabel,
    makeStyles
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useCallback, useEffect, useRef, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TagCheckList from "../Tag/tagCheckList";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        maxWidth: '500px'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textTagContainer: {
        width: "100%",
    },

}));

const BatchTagModal = ({ open, onClose, contactId, onSave, tagsId }) => {
    // Hooks
    const classes = useStyles();
    const isMounted = useRef(false);
    const [submittingContent, setSubmittingContent] = useState(false);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [removePreviousTags, setRemovePreviousTags] = useState(false); // remover tags anteriores

    useEffect(() => {
        if (tagsId && Array.isArray(tagsId)) {
            const initialSelectedTags = tagsId.map(t => t.id);
            setSelectedTags(initialSelectedTags)
        } else {
            setSelectedTags([]);
        }
    }, [tagsId, open]);

    useEffect(() => {
        const fetchTag = async () => {

            if (!isMounted.current) {
                isMounted.current = true;
                try {
                    const { data } = await api.get(`/tags`);
                    const filterTags = data.tags.filter(t => t.status == true || t.status == 1);
                    setTags(filterTags);
                } catch (err) {
                    console.error({err});
                    toastError(err);
                }
            }

        };
        fetchTag();
    }, [contactId, open]);

    // Handlers
    const handleClose = useCallback(() => {
        onClose();
        setSelectedTags([]);
    }, [onClose]);

    const handleTagChange = useCallback((tagId, isChecked) => {
        setSelectedTags((prev) =>
            isChecked ? [...prev, tagId] : prev.filter(id => id !== tagId)
        );
    }, []);

    useEffect(() => {
        setRemovePreviousTags(false); // checkbox não fica marcado quando abrir o modal
    }, [open]);

    // Renderização
    return (
        <div className={classes.root}>
            <Dialog 
                open={open == true ? true : false}
                onClose={handleClose} 
                maxWidth="sm" 
                fullWidth 
                scroll="paper" 
                className="forContactsmodal"
            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
                    {i18n.t("tagForContactsModal.title")}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={removePreviousTags}
                                onChange={() => setRemovePreviousTags(!removePreviousTags)}
                                color="primary"
                            />
                        }
                        style={{ padding: "10px 0", color: "dimgray", display: "block" }}
                        label={i18n.t("tagForContactsModal.removePreviousTags")}
                    />
                </DialogTitle>
                <Divider style={{ backgroundColor: "dimgray", marginBottom: 10 }} />
                <Box component="div" style={{ padding: "10px 10px 10px 13px", maxHeight: "600px", maxWidth: "400px", overflowY: "auto" }}>
                    <TagCheckList tags={tags} selectedTags={selectedTags} onChange={handleTagChange} />
                </Box>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={submittingContent} variant="outlined">
                        {i18n.t("tagForContactsModal.buttons.cancel")}
                    </Button>
                    <Button
                        onClick={() => { onSave("tag", { tags: selectedTags.sort((a, b) => a - b), removePreviousTags }); handleClose(); }}
                        color="primary"
                        disabled={submittingContent}
                        variant="contained"
                    >
                        {i18n.t("tagForContactsModal.buttons.ok")}
                        {submittingContent && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default BatchTagModal;