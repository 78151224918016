import {
    Box,
    Button,
    Checkbox,
    ClickAwayListener,
    Divider,
    Drawer,
    FormControlLabel,
    IconButton,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";

import MoodIcon from "@material-ui/icons/Mood";
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from "@mui/icons-material/DoneAll";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import { makeStyles } from "@mui/styles";

import { MuiChipsInput } from 'mui-chips-input';
import toastError from "../../../../errors/toastError";
import ChatbotIntegrationModal from "../../../ChatbotIntegrationModal/ChatbotIntegrationModal";

import apiService from "../../../../services/api";
import VariableChips from "../VariableChips";
import EmojiDataPicker from "../../../EmojiDataPicker";
import JsonParse from "../../../../helpers/JsonParse";

const useStyles = makeStyles({
    input: {
        margin: "10px 0px 10px 0px !important",
    },
    radioGroup: {
        display: "flex",
        justifyContent: "space-between",
    },
    emojiButton: {
        display: "block",
        width: "fit-content",
        position: "absolute",
        top: "10px",
        right: "0px",
    },
    emojiDisplay: {
        position: "absolute",
        zIndex: "10",
        right: "10px",
        width: "280px",
        "& .emoji-mart": {
            width: "280px!important",
        },
    },
});

const initialStateLGPD = [
    { id: `true`, name: `Aceitar`, keywords: ['Sim', 'sim', 'S', 's'] },
    { id: `false`, name: `Recusar`, keywords: ['Não', 'não', 'N', 'n'] },
]

const LateralMenu = ({
    open,
    onClose,
    object,
    onSyncCard,
    propsObject,
    queues,
    onConfirm,
    variables
}) => {
    const classes = useStyles();
    const [titleMessage, setTitleMessage] = useState("");
    const [message, setMessage] = useState("");
    const [afterMessage, setAfterMessage] = useState("");
    const [type, setType] = useState(propsObject?.type);
    const [subtype, setSubtype] = useState(propsObject?.subtype);
    const [queueSelected, setQueueSelected] = useState(0);
    const [timer, setTimer] = useState("00");
    const [validate, setValidate] = useState("cep");
    const [schedulesArray, setSchedulesArray] = useState([]);
    const [schedules, setSchedules] = useState(null);
    const [showEmoji, setShowEmoji] = useState(0);
    const [syncCard, setSyncCard] = useState(null);
    const [drawerWidth, setDrawerWidth] = useState(425);
    const [nodes, setNodes] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [validate_keywords, setValidateKeywords] = useState([]);
    const [validate_lgpd, setValidateLgpd] = useState(initialStateLGPD);
    const [api, setAPI] = useState({});
    const drawerRef = useRef(null);  // Inicializa o ref

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!open) {
            setDrawerWidth(0);
        } else {
            setSyncCard(null)
            const objects = object();
            setNodes(objects.nodes)
            setDrawerWidth(300);
        }
    }, [open]);

    useEffect(() => {
        if (propsObject && open) {
            const validateLgpdProps = JsonParse(propsObject?.validate_lgpd || '[]').length > 0 ? JsonParse(propsObject?.validate_lgpd || '[]') : initialStateLGPD
            setQueueSelected(propsObject.endFlowOption);
            setType(propsObject.type);
            setSubtype(propsObject.subtype);
            setAfterMessage(propsObject?.afterMessage);
            setMessage(propsObject.lastMessage);
            setTitleMessage(propsObject.lastTitle);
            setTimer(propsObject.timer);
            setValidate(propsObject.validate);
            setValidateKeywords(JsonParse(propsObject?.validate_keywords || '[]'))
            setValidateLgpd(validateLgpdProps)
            setSchedules(propsObject?.schedules)
            setAPI(JsonParse(propsObject?.api || '{}'))
        }
    }, [propsObject, open]);

    useEffect(() => {
        (async () => {
            const { data } = await apiService.get('/openingHour');
            if (Array.isArray(data.openingHours)) {
                setSchedulesArray(data.openingHours.filter(op => op.status == true));
            }
        })();

        if (open && drawerRef.current) {
            drawerRef.current.focus();
        }
    }, [open]);

    const handleChange = () => {
        setQueueSelected((prevQueue) =>
            prevQueue === "capture" ? 0 : "capture"
        );
    };

    const handleClose = () => {
        setValidateKeywords([]);
        setValidateLgpd([]);
        setQueueSelected(0);
        setValidate("cep");
        onClose(false);
    };

    const saveData = () => {

        // verificar se for validação composta, deve conter pelo menos um item cadastrado
        if (propsObject?.subtype === "validate_keywords") {
            if (!validate_keywords || validate_keywords.length == 0) {
                toastError({ response: { data: { error: 'Insira ao menos uma opção.' } } });
                return;
            }

            for (const v of validate_keywords) {
                if (!v.keywords || v.keywords.length == 0) {
                    toastError({ response: { data: { error: `A chave "${v.name}" não possuí nenhuma opção cadastrada.` } } });
                    return;
                }
            }
        }


        // verificar se for validação lgpd, deve conter pelo menos um item cadastrado
        if (propsObject?.subtype === "validate_lgpd" && (!message || message.length === 0)) {
            if (!validate_lgpd || validate_lgpd.length == 0) {
                toastError({ response: { data: { error: 'Insira ao menos uma opção.' } } });
                return;
            }
            if (!message || message.length === 0) {
                toastError({ response: { data: { error: 'A mensagem é obrigatória.' } } });
                return;
            }

            for (const v of validate_lgpd) {
                if ((!v.keywords || v.keywords.length == 0) && v.id !== "invalid_response") {
                    toastError({ response: { data: { error: `A chave "${v.name}" não possuí nenhuma opção cadastrada.` } } });
                    return;
                }
            }
        }


        setTimeout(() => handleClose(), 500);
        onConfirm(titleMessage, message, afterMessage, queueSelected, type, subtype, timer, validate, validate_keywords, validate_lgpd, api, schedules);
    };

    const selectedQueue = (queue) => setQueueSelected(queue);

    const formatMaxTime = (e, limit) => {
        let value = e.target.value.replace(/[^0-9]/g, "");
        value = +value;
        value = value > limit ? +limit : value;

        return ("00" + value).slice(-2);
    };

    const checkParent = (element) => {
        const max = 6;
        let parent = element.parentElement;
        let found = false;

        for (let x = 1; x <= max; x++) {
            if (parent == undefined) break;
            if (parent.classList.contains("emoji-mart")) {
                found = true;
                break;
            }
            parent = parent.parentElement;
        }
        return found;
    };

    const connectNodes = (source, target) => {
        onSyncCard({
            "source": source,
            "sourceHandle": null,
            "target": target,
            "targetHandle": null
        })
        handleCloseMenu();
    };

    const addNewSourceLineValidateKeywords = () => {
        if (validate_keywords.length <= 20) {
            let newValidate = [...validate_keywords];
            newValidate.push({ id: `keyword_${validate_keywords.length + 1}`, name: `keyword ${validate_keywords.length + 1}`, keywords: [] })
            setValidateKeywords(newValidate);
        } else {
            toastError("Limite de variáveis adicionadas atingido.")
        }
    };

    const removeValidateKeywordsSourceLineById = (idToRemove) => {
        // Verifica se o ID é válido e se o objeto com esse ID existe
        const indexToRemove = validate_keywords.findIndex(item => item.id === idToRemove);
        if (indexToRemove !== -1) {
            // Cria uma nova matriz sem o objeto a ser removido
            const newValidate = validate_keywords.filter(item => item.id !== idToRemove);
            setValidateKeywords(newValidate);
        } else {
            console.error('ID não encontrado:', idToRemove);
            // Se o ID não for encontrado, você pode lidar com isso de acordo com sua lógica de aplicativo
        }
    };

    const handleValidateKeywordsChangeChip = (newChips, id, key) => {
        let newValidate = [...validate_keywords];
        const index = newValidate.findIndex(validations => validations.id === id);
        if (index !== -1) {
            newValidate[index] = { ...newValidate[index], [key]: newChips };
            setValidateKeywords(newValidate);
        }
    }

    const handleOpenAPIModal = () => { setOpenModal(true) };

    const handleValidateLGPDChangeChip = (newChips, id, key) => {
        let newValidate = [...validate_lgpd];
        const index = newValidate.findIndex(validations => validations.id === id);
        if (index !== -1) {
            newValidate[index] = { ...newValidate[index], [key]: newChips };
            setValidateLgpd(newValidate);
        }
    }

    return (
        <Drawer
            ref={drawerRef}  // Referência para o Drawer
            component="div"
            tabIndex={0}
            style={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                },
            }}
            variant="persistent"
            anchor="right"
            open={open == true ? true : false}
            onKeyDown={(event) => {
                if (event.key === 'Escape') {
                    event.preventDefault();
                    event.stopPropagation();
                    handleClose();
                }
            }}
        >
            <ChatbotIntegrationModal
                integrationData={api}
                open={openModal}
                onClose={() => setOpenModal(false)}
                onConfirm={values => setAPI(values)}
            />
            <Box component="div">
                <IconButton onClick={handleClose}>
                    <ChevronRightIcon />
                </IconButton>
            </Box>
            <Divider />
            <Box component="div" style={{ padding: 10, height: "100%", width: drawerWidth }}>
                <Box component="div">
                    <TextField
                        className={classes.input}
                        value={titleMessage}
                        onChange={(e) => setTitleMessage(e.target.value)}
                        label="Titulo"
                        variant="outlined"
                        fullWidth
                    />
                    {propsObject?.subtype === "validate_keywords" && (
                        <React.Fragment>
                            {validate_keywords.length > 0 && validate_keywords.map(key => (
                                <Box style={{
                                    backgroundColor: 'gainsboro',
                                    padding: '10px',
                                    margin: '5px',
                                    borderRadius: '5px',
                                    display: key?.id === "invalid_response" ? "none" : "block"
                                }}>
                                    <Box style={{ display: 'flex' }}>
                                        <TextField
                                            className={classes.input}
                                            value={key.name}
                                            onChange={(e) => handleValidateKeywordsChangeChip(e.target.value, key.id, 'name')}
                                            label="Nome"
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <IconButton onClick={() => removeValidateKeywordsSourceLineById(key.id)} aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>

                                    <MuiChipsInput
                                        style={{ width: '100%' }}
                                        size="small"
                                        placeholder="Digite e pressione enter"
                                        value={key?.keywords || []}
                                        onChange={(e) => handleValidateKeywordsChangeChip(e, key.id, 'keywords')}
                                    />
                                </Box>
                            ))}
                            <Button
                                onClick={() => addNewSourceLineValidateKeywords()}
                                variant="outlined"
                                startIcon={<AddIcon />}
                                style={{ width: '100%', margin: '15px 0px' }}
                            >
                                Nova variável
                            </Button>
                        </React.Fragment>)}
                    {propsObject?.subtype === "validate_lgpd" && (
                        <React.Fragment>
                            {validate_lgpd.length > 0 && validate_lgpd.map(key => (
                                <Box style={{
                                    backgroundColor: 'gainsboro',
                                    padding: '10px',
                                    margin: '5px',
                                    borderRadius: '5px',
                                    display: key?.id === "invalid_response" ? "none" : "block"
                                }}>
                                    <Box style={{ display: 'flex' }}>
                                        <TextField
                                            className={classes.input}
                                            value={key.name}
                                            onChange={(e) => handleValidateLGPDChangeChip(e.target.value, key.id, 'name')}
                                            label="Nome"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Box>

                                    <MuiChipsInput
                                        style={{ width: '100%' }}
                                        size="small"
                                        placeholder="Digite e pressione enter"
                                        value={key?.keywords || []}
                                        onChange={(e) => handleValidateLGPDChangeChip(e, key.id, 'keywords')}
                                    />
                                </Box>
                            ))}
                        </React.Fragment>)}
                    <Box>
                        {
                            (subtype != 'validate_keywords' && subtype != 'validate_lgpd') &&
                            <Button style={{ width: '100%', margin: '10px 0px' }} variant="contained" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                Vincular Passo
                            </Button>
                        }
                        {propsObject?.subtype === "api" && (
                            <Button style={{ width: '100%', margin: '10px 0px' }} variant="outlined" aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleOpenAPIModal()}>
                                Editar API
                            </Button>
                        )}
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            {nodes.map((node) => (
                                <React.Fragment>
                                    {!node || !propsObject || node.type == "start" ?
                                        null :
                                        <React.Fragment>
                                            <MenuItem disabled={propsObject.id == node.id} onClick={e => connectNodes(propsObject.id, node.id)} key={node.id} value={node.id}>
                                                <Box component="div" style={{ display: "flex" }}>
                                                    {node.title} {propsObject.id == node.id ? " (CARD ATUAL)" : ""}
                                                </Box>
                                            </MenuItem>
                                        </React.Fragment>}
                                </React.Fragment>
                            ))}
                        </Menu>
                    </Box>

                    {propsObject?.subtype !== "tmp" && propsObject?.subtype !== "validate" && propsObject?.subtype !== "start" && propsObject?.subtype !== "api" && propsObject?.subtype !== "validate_keywords" && (
                        <>
                            <VariableChips
                                variables={variables}
                                onClick={(text) => { setMessage(`${message}${text}`) }}
                            />
                            <Box style={{ position: "relative" }}>
                                <TextField
                                    className={classes.input}
                                    multiline
                                    fullWidth
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    label="Mensagem"
                                    variant="outlined"
                                />
                                <Box className={classes.emojiButton}>
                                    <EmojiDataPicker
                                        onEmojiSelect={(emoji) => {
                                            setMessage(`${message}${emoji}`);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                    {(propsObject?.subtype === "conditional" ||
                        propsObject?.subtype === "cond") && (
                            <>
                                <FormControlLabel
                                    value="Salvar resposta"
                                    control={
                                        <Checkbox
                                            checked={queueSelected === "capture"}
                                            onChange={handleChange}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label="Salvar resposta"
                                    labelPlacement="Salvar resposta"
                                />
                                {queueSelected === "capture" && (
                                    <Box style={{ position: "relative" }}>
                                        <TextField
                                            className={classes.input}
                                            multiline
                                            fullWidth
                                            value={afterMessage}
                                            onChange={(e) => setAfterMessage(e.target.value)}
                                            label="Resposta após captura"
                                            variant="outlined"
                                        />
                                        <Box className={classes.emojiButton}>
                                            <EmojiDataPicker
                                                onEmojiSelect={(emoji) => {
                                                    setAfterMessage(`${afterMessage}${emoji}`);
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                )}
                            </>
                        )}
                    {propsObject?.subtype === "end" && (
                        <TextField
                            className={classes.input}
                            multiline
                            fullWidth
                            value={queueSelected}
                            select
                            onChange={(e) => selectedQueue(e.target.value)}
                            label="Finalizar fluxo"
                            variant="outlined"
                        >
                            <MenuItem key={0} value={0}>
                                <Box component="div" style={{ display: "flex" }}>
                                    {" "}
                                    Finalizar atendimento <DoneAllIcon style={{ marginLeft: 5 }} />
                                </Box>
                            </MenuItem>
                        </TextField>
                    )}
                    {propsObject?.subtype === "transfer" && (
                        <TextField
                            className={classes.input}
                            multiline
                            fullWidth
                            value={queueSelected}
                            select
                            onChange={(e) => selectedQueue(e.target.value)}
                            label="Transferir"
                            variant="outlined"
                        >
                            {queues.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    <Box component="div" style={{ display: "flex" }}>
                                        {" "}
                                        Transferir para {option.name}{" "}
                                        <MoveUpIcon style={{ marginLeft: 5 }} />
                                    </Box>
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    {type !== "start" && (
                        <Box component="div" style={{ display: "none" }}>
                            <label>Tipo</label>
                            <RadioGroup
                                hidden={propsObject?.type !== "start"}
                                className={classes.radioGroup}
                                row
                                onChange={(e) => setType(e.target.value)}
                                value={type}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="end" control={<Radio />} label="Finalização" />
                                <FormControlLabel value="conditional" control={<Radio />} label="Condicional" />
                            </RadioGroup>
                        </Box>
                    )}
                    {propsObject?.subtype === "tmp" && (
                        <TextField
                            className={classes.input}
                            fullWidth
                            label="Segundos"
                            variant="outlined"
                            value={timer}
                            onChange={(e) => setTimer(formatMaxTime(e, 59))}
                        />
                    )}

                    {propsObject?.subtype === "validate" && (
                        <>
                            <TextField
                                className={classes.input}
                                fullWidth
                                value={validate}
                                select
                                onChange={(e) => setValidate(e.target.value)}
                                label="Validação"
                                variant="outlined"
                            >
                                <MenuItem key={0} value={"cep"}>
                                    <Box component="div" style={{ display: "flex" }}>
                                        CEP
                                    </Box>
                                </MenuItem>
                                <MenuItem key={0} value={"cpf"}>
                                    <Box component="div" style={{ display: "flex" }}>
                                        CPF
                                    </Box>
                                </MenuItem>
                                <MenuItem key={0} value={"cnpj"}>
                                    <Box component="div" style={{ display: "flex" }}>
                                        CNPJ
                                    </Box>
                                </MenuItem>
                                <MenuItem key={0} value={"telefone"}>
                                    <Box component="div" style={{ display: "flex" }}>
                                        Telefone
                                    </Box>
                                </MenuItem>
                                <MenuItem key={0} value={"horarios"}>
                                    <Box component="div" style={{ display: "flex" }}>
                                        Horários
                                    </Box>
                                </MenuItem>
                            </TextField>
                            {validate === "horarios" && (
                                <TextField
                                    className={classes.input}
                                    fullWidth
                                    value={schedules}
                                    select
                                    onChange={(e) => setSchedules(e.target.value)}
                                    label="Horarios de funcionamento"
                                    variant="outlined"
                                >
                                    {schedulesArray.map(horario => (
                                        <MenuItem key={horario.id} value={horario.id}>
                                            <Box component="div" style={{ display: "flex" }}>
                                                {horario.name}
                                            </Box>
                                        </MenuItem>))}
                                </TextField>
                            )}
                        </>
                    )}

                </Box>

                <Box
                    component="div"
                    style={{ display: "flex", justifyContent: "space-around", marginTop: 20 }}
                >
                    <Button variant="contained" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={saveData}>
                        Confirmar
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
};

export default LateralMenu;
