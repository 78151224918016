const FilteredTickets = (tickets, tagsFilter, whatsappNumbers, user, queues) => {

    return tickets.filter(ticket => {
        const { Contact, userId, numberConnection, whatsapp, queueId } = ticket;

        // Filtrar etiquetas
        const hasTags = Contact?.tagsId && Array.isArray(Contact?.tagsId) && Contact.tagsId.length > 0;
        const hasTagsFilter = tagsFilter && Array.isArray(tagsFilter) && tagsFilter.length > 0;

        const hasMatchingTag = hasTags
            ? Contact.tagsId.some(tag => tagsFilter.includes(tag.id)) && hasTagsFilter
            : hasTagsFilter && tagsFilter.includes(null);  // Verifica se null ou array vazio está no filtro

        if (!hasMatchingTag) return false;

        // Verificar atendente
        if (userId && userId !== user.id) return false;

        // Verificar conexão se o módulo for de WhatsApp
        if (whatsapp.moduleId === 1 && !whatsappNumbers.includes(numberConnection)) return false;

        // Verificar fila
        if (!queues.includes(queueId)) return false;

        return true;
    });
};

export default FilteredTickets;