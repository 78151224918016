import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ChatbotModal = ({ open, onClose, chatbotId }) => {
  const classes = useStyles();

  const initialState = { name: "", isReceptive: 1 };
  const [chatbot, setChatbot] = useState(initialState);
  const [whatsapps, setWhatsapps] = useState([]);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);
  const types = [{
    name: "Receptivo",
    value: 1
  },{
    name: "Ativo",
    value: 0
  }];

  useEffect(() => {
    chatbot?.WhatsappChatbots?.forEach(({whatsappId}) => {
      setSelectedWhatsapp(whatsappId);
    });
  }, [chatbot]);

  useEffect(() => {
    const getWhatsapps = async () => {
      const { data } = await api.get(`/whatsapp/`);
      setWhatsapps(data)
    };
    getWhatsapps();
  }, []);

  useEffect(() => {
    const fetchChatbot = async () => {
      if (!chatbotId) {
        setChatbot(initialState);
        return;
      }

      try {
        const { data } = await api.get(`/chatbot/${chatbotId}`);
        setChatbot(prevState => ({ ...prevState, name: data.name, WhatsappChatbots: data.WhatsappChatbots, isReceptive: data.isReceptive == true ? 1 : 0 }));
      } catch (err) {
        console.error({err});
        toastError(err);
      }
    };

    fetchChatbot();
  }, [chatbotId, open]);

  const handleClose = () => {
    onClose();
    setChatbot(initialState);
  };

  const handleSaveChatbot = async values => {

    if (!selectedWhatsapp) return toast.error('Defina uma conexão de whatsapp para criar o ChatBot');

    const chatbotData = { ...values, whatsappId: selectedWhatsapp };

    if (chatbotData.name.length < 3) return toast.error('Insira um nome maior que 3 dígitos para o Chatbot');

    try {
      if (chatbotId) {
        await api.put(`/chatbot/${chatbotId}`, chatbotData);
        toast.success('Atualizado com sucesso!');
        handleClose();
      } else {
        await api.post("/chatbot", chatbotData);
        toast.success('Cadastrado com sucesso!');
        handleClose();
      }
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  };

  const handleSelectWhatsapp = wppId => setSelectedWhatsapp(wppId);

  return (
    <div className={classes.root}>
      <Dialog
        open={open == true ? true : false}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {chatbotId
            ? `${'Editar Chatbot'}`
            : `${'Cadastrar Chatbot'}`}
        </DialogTitle>
        <Formik
          initialValues={chatbot}
          enableReinitialize={true}
          validationSchema={Yup.object({
            name: Yup.string().required('O nome é obrigatório'),
            isReceptive: Yup.string().required('O tipo de atendimento é obrigatório'),
          })}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveChatbot(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form style={{ padding: "10px" }}>
              <DialogContent dividers>
                <div className="form-row">
                  <Field type="hidden" name="adminId" />
                  <div className="form-group col-md-12">
                    <label>{'Nome'}</label>
                    <Field
                      style={{
                        borderColor: '#c4c4c4',
                        borderRadius: '3px'
                      }}
                      onChange={e => setChatbot({ ...chatbot, name: e.target.value })}
                      className='form-control'
                      placeholder={'Defina um nome para o chatbot'}
                      type="text"
                      name="name"
                      required
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label>{'Conexão'}</label>
                    <Field
                      style={{ width: '100%' }}
                      as={Select}
                      id="plan-selection"
                      label="Plano"
                      onClick={e => handleSelectWhatsapp(e.target.value)}
                      labelId="plan-selection-label"
                      name="planId"
                      value={selectedWhatsapp}
                      margin="dense"
                      required
                      variant="outlined"
                      disabled={chatbotId ? true : false}
                    >
                      {whatsapps.map((whatsapp, key) => (
                        <MenuItem key={key} value={whatsapp.id} disabled={whatsapp.status != 'CONNECTED'}>
                          {whatsapp.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </div>
                  <div className="form-group col-md-12">
                    <label>{'Tipo de Atendimento'}</label>
                    <Field
                      style={{ width: '100%' }}
                      as={Select}
                      id="isReceptive"
                      label="Tipo de Atendimento"
                      onClick={e => setChatbot({ ...chatbot, isReceptive: e.target.value })}
                      labelId="plan-selection-label"
                      name="isReceptive"
                      value={chatbot.isReceptive}
                      margin="dense"
                      required
                      variant="outlined"
                      disabled={chatbotId ? true : false}
                    >
                      {types.map((type, key) => (
                        <MenuItem key={key} value={type.value}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className={classes.multFieldLine}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    margin="dense"
                  >
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {'Cancelar'}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {chatbotId
                    ? `${'Salvar'}`
                    : `${'Cadastrar'}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ChatbotModal;