import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CheckPassword from "../../helpers/CheckPassword";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	inputLabel: {
		fontSize: "1.2rem",
		"& label": {
			top: "-5px",
			background: "#fff",
			padding: "0 10px",
			left: "-10px",
		}
	}
}));

const ChangePassword = ({ open, onClose, userId }) => {
	const classes = useStyles();
	const initialState = {
		password: "",
		newPassword: "",
		confirmPassword: "",
	};
	const [user, setUser] = useState(initialState);
	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showCPassword, setShowCPassword] = useState(false);

	const UserPasswordSchema = Yup.object().shape({
		password: Yup.string()
			.required(`${i18n.t("changePassword.modal.password")} ${i18n.t("forms.required")}`),
		newPassword: Yup.string()
			.required(`${i18n.t("changePassword.modal.newPassword")} ${i18n.t("forms.required")}`)
			.test('newPassword', `Utilize letras maiusculas, minusculas, caracteres especiais e númericos para sua nova senha.`, function (value) {
				return CheckPassword(value)
			}),
		confirmPassword: Yup.string()
			.required(`${i18n.t("changePassword.modal.confirmPassword")} ${i18n.t("forms.required")}`)
			.test('confirmPassword', `${i18n.t("changePassword.modal.confirmPassword")} ${i18n.t("forms.invalid")}`, function (value) {
				return value == user.newPassword
			}),
	});
	// reordenar para seguir sequencia correta
    if (UserPasswordSchema['_nodes']) UserPasswordSchema['_nodes'] = UserPasswordSchema['_nodes'].reverse();

	const handleClose = () => {
		setUser(initialState);
		setShowPassword(false);
		setShowNewPassword(false);
		setShowCPassword(false);
		onClose();
	};

	const handleSaveUser = async () => {
		if (!userId) return;
		let validated = false;
		try {

			await UserPasswordSchema.validate(user);
			validated = true;

			await api.put(`/users/changePassword/${userId}`, user);
			toast.success(i18n.t("changePassword.success"));
			setTimeout(() => { handleClose(); }, 1000);
		} catch (err) {
			console.info({err})
			if (validated == false) toastError({ response: { data: { error: err?.message } } });
			else toastError(err);;
		}
	};

	return (
		<Box className={classes.root}>
			<Dialog 
				open={open == true ? true : false}
				onClose={handleClose} 
				maxWidth="sm" 
				fullWidth 
				scroll="paper"
			>
				<Box style={{ display: "flex", justifyContent: "space-between" }}>
					<DialogTitle>{`${i18n.t("changePassword.modal.title")}`}</DialogTitle>
				</Box>

				<Formik
					initialValues={user}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser();
							actions.setSubmitting(false);
						}, 1000);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form style={{ padding: "10px" }}>
							<DialogContent dividers>
								<Grid container spacing={2}>
									<Grid item xs={12} md={12} mb={2}>
										<TextField
											label={
												<>
													{i18n.t("changePassword.modal.password")}
													<Typography
														variant="h6"
														component="label"
														style={{ lineHeight: "normal", color: "#f44336" }}
													>
														*
													</Typography>
												</>
											}
											type={showPassword ? "text" : "password"}
											fullWidth
											variant="outlined"
											className={classes.inputLabel}
											inputProps={{ maxLength: 20 }}
											onBlur={(e) => setUser({ ...user, password: e.target.value })}
											InputProps={{
												endAdornment: (
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword(!showPassword)}
														edge="end"
													>
														{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
													</IconButton>
												),
											}}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6} mb={2}>
										<TextField
											margin="dense"
											label={
												<>
													{i18n.t("changePassword.modal.newPassword")}
													<Typography
														variant="h6"
														component="label"
														style={{ lineHeight: "normal", color: "#f44336" }}
													>
														*
													</Typography>
												</>
											}
											type={showNewPassword ? "text" : "password"}
											fullWidth
											variant="outlined"
											className={classes.inputLabel}
											inputProps={{ maxLength: 20 }}
											onBlur={(e) => setUser({ ...user, newPassword: e.target.value })}
											InputProps={{
												endAdornment: (
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowNewPassword(!showNewPassword)}
														edge="end"
													>
														{showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
													</IconButton>
												),
											}}
										/>
									</Grid>
									<Grid item xs={12} md={6} mb={2}>
										<TextField
											margin="dense"
											label={
												<>
													{i18n.t("changePassword.modal.confirmPassword")}
													<Typography
														variant="h6"
														component="label"
														style={{ lineHeight: "normal", color: "#f44336" }}
													>
														*
													</Typography>
												</>
											}
											type={showCPassword ? "text" : "password"}
											fullWidth
											variant="outlined"
											className={classes.inputLabel}
											inputProps={{ maxLength: 20 }}
											onBlur={(e) => setUser({ ...user, confirmPassword: e.target.value })}
											InputProps={{
												endAdornment: (
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowCPassword(!showCPassword)}
														edge="end"
													>
														{showCPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
													</IconButton>
												),
											}}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12} md={12} mb={2}>
										<Box
											style={{
												background: '#ffcdd2',
												borderRadius: '5px',
												padding: '10px'
											}}
										>
											<Typography 
												variant="h6"
												style={{
													fontWeight: '600',
													fontSize: '1rem',
													color: '#424242',
												}}
											>
												{i18n.t("changePassword.modal.newPasswordAlerts.title")}
											</Typography>
											<Typography style={{marginLeft: '5px'}}>{i18n.t("changePassword.modal.newPasswordAlerts.qty")}</Typography>
											<Typography style={{marginLeft: '5px'}}>{i18n.t("changePassword.modal.newPasswordAlerts.uppercase")}</Typography>
											<Typography style={{marginLeft: '5px'}}>{i18n.t("changePassword.modal.newPasswordAlerts.lowercase")}</Typography>
											<Typography style={{marginLeft: '5px'}}>{i18n.t("changePassword.modal.newPasswordAlerts.special")}</Typography>
											<Typography style={{marginLeft: '5px'}}>{i18n.t("changePassword.modal.newPasswordAlerts.number")}</Typography>
										</Box>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("changePassword.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{`${i18n.t("changePassword.buttons.add")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</Box>
	);
};

export default ChangePassword;
