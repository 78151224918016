import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import Switch from '@material-ui/core/Switch';
import imageSvg from "../../assets/svg/image.svg";
import csvSvg from "../../assets/svg/csv.svg";
import xlsxSvg from "../../assets/svg/xlsx.svg";
import pdfSvg from "../../assets/svg/pdf.svg";
import musicSvg from "../../assets/svg/music.svg";
import { Can } from "../Can";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    maxWidth: '500px'
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textConclusionMessageContainer: {
    width: "100%",
  },
}));

const ConclusionMessageSchema = Yup.object().shape({
  shortcut: Yup.string()
    .min(2, "Muito Curto(a)!")
    .max(15, "Muito Longo(a)!")
    .required("Obrigatório"),
  message: Yup.string()
    .min(8, "Muito Curto(a)!")
    .max(30000, "Muito Longo(a)!")
    .required("Obrigatório"),
});

const ConclusionMessagesModal = ({
  open,
  onClose,
  conclusionMessageId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const { user: loggedInUser } = useContext(AuthContext);
  const [submittingContent, setSubmittingContent] = useState(false);

  const initialState = {
    adminId: loggedInUser.adminId,
    shortcut: "",
    message: "",
  };

  const [quickAnswer, setConclusionMessage] = useState(initialState);

  
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchConclusionMessage = async () => {
      if (initialValues) {
        setConclusionMessage((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!conclusionMessageId) return;

      try {
        const { data } = await api.get(`/conclusionMessages/${conclusionMessageId}`);
        if (isMounted.current) {
          const array = {
            'adminId': data.adminId,
            'shortcut': data.shortcut,
            'message': data.message,
          }
          setConclusionMessage(array);
        }
      } catch (err) {
        console.error({err});
        toastError(err);
      }
    };

    fetchConclusionMessage();
  }, [conclusionMessageId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setConclusionMessage(initialState);
  };

  const handleSaveConclusionMessage = async (values) => {
    try {
      if (conclusionMessageId) {
        await api.put(`/conclusionMessages/${conclusionMessageId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/conclusionMessages", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("quickConcludeMessage.success"));
      setSubmittingContent(false);
    } catch (err) {
      console.error({err});
      err.response.data.error = i18n.t(err.response.data.error);
      toastError(err);
      setSubmittingContent(false);
    }
  };


  useEffect(() => {
    const fetchConclusionMessage = async () => {
      if (initialValues) {
        setConclusionMessage((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!conclusionMessageId) return;
      try {
        const { data } = await api.get(`/conclusionMessages/${conclusionMessageId}`);
        if (isMounted.current) {
          const array = {
            'adminId': data.adminId,
            'shortcut': data.shortcut,
            'message': data.message,
          }
          setConclusionMessage(array);
        }
      } catch (err) {
        console.error({err});
        toastError(err);
      }
    };

    fetchConclusionMessage();
  }, [conclusionMessageId, open, initialValues]);


  return (
    <div className={classes.root}>
      <Dialog
        open={open == true ? true : false}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {conclusionMessageId
            ? `${i18n.t("quickConcludeMessage.title.edit")}`
            : `${i18n.t("quickConcludeMessage.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={quickAnswer}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setSubmittingContent(true);
            setTimeout(() => {
              const array = {
                'adminId': values.adminId,
                'shortcut': values.shortcut,
                'message': values.message,
              }
              handleSaveConclusionMessage(array);
            }, 400);
          }}
        >
          {({ values, errors, touched }) => (
            <Form style={{ padding: "10px" }}>
              <div className="form-row">
                <Field
                  as={TextField}
                  type="hidden"
                  name="adminId"
                />

                <div className="form-group col-md-12 pl-3 pr-3">
                  <label>{i18n.t("quickConcludeMessage.form.shortcut")} <span className="text-danger ml-2">*</span></label>
                  <Field
                    style={{
                      borderColor: '#c4c4c4',
                      borderRadius: '3px'
                    }}
                    className='form-control'
                    placeholder={i18n.t("quickConcludeMessage.form.shortcut")}
                    name="shortcut"
                    autoFocus
                    error={touched.shortcut && Boolean(errors.shortcut)}
                    helperText={touched.shortcut && errors.shortcut}
                  /> 
                </div>
                <div className="form-group col-md-12 pl-3 pr-3">
                  <label>{i18n.t("quickConcludeMessage.form.message")} </label>
                  <Field
                    as="textarea"
                    style={{
                      borderColor: '#c4c4c4',
                      borderRadius: '3px'
                    }}
                    rows='2'
                    className='form-control '
                    placeholder={i18n.t("quickConcludeMessage.form.message")}
                    name="message"
                  />
                </div>
              </div>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={submittingContent}
                  variant="outlined"
                >
                  {i18n.t("quickConcludeMessage.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={submittingContent}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {conclusionMessageId
                    ? `${i18n.t("quickConcludeMessage.buttons.okEdit")}`
                    : `${i18n.t("quickConcludeMessage.buttons.okAdd")}`}
                  {submittingContent && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div >
  );
};

export default ConclusionMessagesModal;
