import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Box } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	}
}));

const CompanyDatabaseModal = ({ open, onClose, companyId }) => {
	const classes = useStyles();
	const initialState = {
		host: "",
		user: "",
		password: "",
		database: "",
		port: "",
		adminId: "",
		updatedAt: ""
	};
	const [company, setCompany] = useState(initialState);
	const [unseenPassword, setUnseenPassword] = useState(true);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (!open) return;
		const getDatabase = async () => {
			try {
				setLoading(true);
				const { data } = await api.get(`/companyDatabase/${companyId}`);
				const { host, user, password, database, port, adminId, updatedAt } = data;
				setCompany({ host, user, password, database, port, adminId, updatedAt });
			} catch (err) {
				console.error({err});
				toastError(err);
			} finally {
				setLoading(false);
			}
		};

		getDatabase();
	}, [companyId, open]);

	const handleClose = () => {
		onClose();
		setCompany({ ...initialState });
	};

	const handleSaveCompany = async values => {
		try {
			setLoading(true);
			await api.post(`/companyDatabase/store`, values);
			toast.success('Salvo com sucesso!');
		} catch (err) {
			console.error({err});
			toastError(err);
		} finally {
			setLoading(false);
		}

	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open == true ? true : false}
				onClose={handleClose}
				maxWidth="md"
				fullWidth

				scroll="paper"

			>
				<DialogTitle id="form-dialog-title">
					{companyId
						? `${'Editar Banco de Dados'}`
						: `${'Banco de Dados'}`}
				</DialogTitle>
				<Formik
					initialValues={company}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							const { host, user, password, database, port, adminId, updatedAt } = values;
							handleSaveCompany({ host, user, password, database, port, adminId, updatedAt });
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form style={{ padding: "10px" }}>

							<DialogContent dividers>
								<div className="form-row">
									<Field
										type="hidden"
										name="adminId"
									/>
									<div class="form-group col-md-4">
										<label>Endereço</label>
										<Field
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px'
											}}
											className='form-control'
											placeholder={'Endereço'}
											type="text"
											name="host"
											required
											maxlength="50"
										/>
									</div>
									<div class="form-group col-md-4">
										<label>Banco de dados</label>
										<Field
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px'
											}}
											className='form-control'
											placeholder={'Banco de dados'}
											type="text"
											name="database"
											required
											maxlength="50"
										/>
									</div>
									<div class="form-group col-md-4">
										<label>Porta</label>
										<Field
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px'
											}}
											className='form-control'
											placeholder={'Porta'}
											type="text"
											name="port"
											required
											maxlength="10"
										/>
									</div>
									<div class="form-group col-md-6">
										<label>Usuário</label>
										<Field
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px'
											}}
											className='form-control'
											placeholder={'Usuário'}
											type="text"
											name="user"
											required
											maxlength="50"
										/>
									</div>
									<div class="form-group col-md-6">
										<label>Senha</label>
										<Field
											style={{
												borderColor: '#c4c4c4',
												borderRadius: '3px'
											}}
											className='form-control'
											placeholder={'Senha'}
											type={unseenPassword ? 'password' : 'text'}
											name="password"
											required
											maxlength="50"
										>
										</Field>
										<Box 
											style={{
												position: 'absolute',
												right: '20px',
												bottom: '10px',
												cursor: 'pointer'
											}}
											onClick={() => setUnseenPassword(!unseenPassword)}
										>
											{
												unseenPassword ?
												<VisibilityOffIcon />
												:
												<VisibilityIcon />
											}
										</Box>
									</div>
								</div>
								<div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
									</FormControl>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={loading}
									variant="outlined"
								>
									{'cancelar'}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={loading}
									variant="contained"
									className={classes.btnWrapper}
								>
									{companyId
										? `${'Salvar'}`
										: `${'Salvar'}`}
									{loading && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>

			</Dialog>
		</div>
	);
};

export default CompanyDatabaseModal;
