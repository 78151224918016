import { useContext, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MultiSelect from "../InputStyled/multiselect";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";
import { FormControlLabel, Switch, Grid, Select, MenuItem } from "@material-ui/core";

import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const HistoryAccessModal = ({ open, onClose, queueId }) => {
	const classes = useStyles();
	const [queues, setQueues] = useState([]);
	const [selectedQueues, setSelectedQueues] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		(async () => {
			if (!queueId) return;
			try {
				const { data } = await api.get(`/all_queue`, {
					params: { showChatbot: true }
				});
				setQueues(data.filter((dt) => dt.id !== queueId));

				const tmp = await api.get(`/queueHistoryAccess/${queueId}`);
				if (tmp.data) setSelectedQueues(tmp.data.map((dt) => dt.accessQueueId));
			} catch (err) {
				console.error({ err });
				toastError(err);
			}
		})();
	}, [queueId]);

	const handleClose = (event, reason) => {
		if (reason === "backdropClick") {
			event.preventDefault();
			return;
		}
		setQueues([]);
		setSelectedQueues([]);
		setLoading(false);
		onClose();
	};

	const handleSelectedOptions = (options) => {
		setSelectedQueues(Array.isArray(options) ? options : []);
	};

	const handleSave = () => {
		(async () => {
			setLoading(true);
			try {
				const {data} = await api.post(`/queueHistoryAccess/store`, {
					queueId,
					selectedQueues
				});
			
				setTimeout(() => {
					handleClose();
				}, 500);
			} catch (err) {
				console.error({ err });
				toastError(err);
				setLoading(false);
			}
		})();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open == true ? true : false}
				onClose={(event, reason) => handleClose(event, reason)}
				scroll="paper"
				fullWidth
				maxWidth="sm"
				disableEscapeKeyDown
			>
				<DialogTitle>{i18n.t("queueHistory.title")}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<MultiSelect
								title={i18n.t("queueHistory.info")}
								displayText="Selecionar filas"
								disabled={!queues}
								options={queues.map((q) => ({ key: q.id, value: q.name }))}
								onSelected={handleSelectedOptions}
								selecteds={selectedQueues}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("queueModal.buttons.cancel")}
					</Button>
					<Button
						onClick={handleSave}
						color="primary"
						disabled={loading}
						variant="contained"
						className={classes.btnWrapper}
					>
						{i18n.t("queueModal.buttons.okAdd")}
						{loading && (
							<CircularProgress size={24} className={classes.buttonProgress} />
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default HistoryAccessModal;