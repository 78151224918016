import { useContext, useEffect, useRef, useState } from "react";

import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    TextField,
    makeStyles
} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import { green } from "@material-ui/core/colors";
import Block from '@uiw/react-color-block';
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import "./tagModal.css";
import { MuiChipsInput } from 'mui-chips-input';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        maxWidth: '500px'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textTagContainer: {
        width: "100%",
    },
    keywords: {
        width: "100%",
        "& input": {
            width: "100%!important"
        }
    }
}));

const TagModal = ({
    open,
    onClose,
    tagId,
    initialValues,
    onSave,
}) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);
    const { user: loggedInUser } = useContext(AuthContext);
    const [submittingContent, setSubmittingContent] = useState(false);
    const [hex, setHex] = useState("");
    const [colors, setColors] = useState(['#b71c1c','#d32f2f','#f44336','#c2185b','#e91e63','#ff5722','#ff9800','#ffc107','#ffeb3b','#cddc39','#8bc34a','#4caf50','#009688','#00bcd4','#03a9f4','#2196f3','#3f51b5','#673ab7','#9c27b0','#9e9e9e']);

    const initialState = {
        name: "",
        color: "",
        keywords: [],
        updatedBy: "",
        status: true,
    };

    const [tag, setTag] = useState(initialState);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const fetchTag = async () => {
            if (initialValues) {
                setTag((prevState) => {
                    return { ...prevState, ...initialValues };
                });
            }

            try {
                const { data } = await api.get(`/tags`);
                if (data.tags) {
                    let colorsTemp = colors;
                    data.tags.map(item => {
                        colorsTemp = colorsTemp.filter((color) => color != item.color);
                    });
                    setColors(colorsTemp);
                }
            } catch (err) {
                console.error({err});
            }

            if (!tagId) return;

            try {
                const { data } = await api.get(`/tags/${tagId}`);
                const array = {
                    'adminId': data.adminId,
                    'name': data.name,
                    'color': data.color,
                    'status': data.status,
                    'keywords': data.keywords
                }
                setColors([...colors, data.color]);
                setTag(array);
                setHex(data.color);
            } catch (err) {
                console.error({err});
                toastError(err);
            }
        };

        fetchTag();
    }, [tagId, open, initialValues]);

    const handleClose = () => {
        onClose();
        setTag(initialState);
        setHex('');
    };

    const handleSaveTag = async (values) => {
        try {
            if (tagId) {
                await api.put(`/tags/${tagId}`, values);
                handleClose();
            } else {
                const { data } = await api.post("/tags", values);
                if (onSave) {
                    onSave(data);
                }
                handleClose();
            }
            toast.success(i18n.t("tagModal.success"));
            setSubmittingContent(false);
        } catch (err) {
            console.error({err});
            err.response.data.error = i18n.t(err.response.data.error);
            toastError(err);
            setSubmittingContent(false);
        }
    };

    const handleChangeChip = (keywords) => {
        const removeDuplicates = [...new Set(keywords)];
        if (removeDuplicates.length > 10) return;
        setTag({ ...tag, keywords: removeDuplicates });
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open == true ? true : false}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {tagId
                        ? `${i18n.t("tagModal.title.edit")}`
                        : `${i18n.t("tagModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={tag}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        setSubmittingContent(true);
                        setTimeout(() => {
                            let array = {
                                'adminId': loggedInUser.adminId,
                                'updatedBy': user.id,
                                'name': values.name,
                                'color': hex,
                                'status': values.status,
                                'id': !tagId ? null: tagId,
                                'keywords': values.keywords

                            }
                            handleSaveTag(array);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form style={{ padding: "10px" }}>
                            <div className="form-row">
                                <Field
                                    as={TextField}
                                    type="hidden"
                                    name="adminId"
                                />
                                <div class="form-group col-md-12 pl-3">
                                    <label>
                                        {i18n.t("tagModal.form.name")}
                                        <span className="text-danger ml-2">*</span>
                                    </label>
                                    <Field
                                        style={{
                                            borderColor: '#c4c4c4',
                                            borderRadius: '3px'
                                        }}
                                        className='form-control'
                                        placeholder={i18n.t("tagModal.form.name")}
                                        name="name"
                                        autoFocus
                                        required={true}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        maxLength={20}
                                        onChange={(e) => { setTag({ ...tag, name: e.target.value }); }}
                                    />
                                </div>
                                <div class="form-group col-md-12 pl-3">
                                    <label>
                                        {i18n.t("tagModal.form.keywords")}
                                    </label>
                                    <MuiChipsInput
                                        className={classes.keywords}
                                        size="small"
                                        placeholder="Digite e pressione enter"
                                        value={values?.keywords || []}
                                        onChange={(e) => handleChangeChip(e)}
                                    />
                                </div>
                                <div class="form-group col-md-12 pl-3">
                                    <label>
                                        {i18n.t("tagModal.form.color")} 
                                        <span className="text-danger ml-2">*</span>
                                    </label>
                                    <>
                                        <div style={{ background: hex, margin: "10px", padding: "10px", userSelect: "none" }}>
                                            {hex}
                                        </div>
                                        <Block
                                            style={{
                                                width: "100%"
                                            }}
                                            color={hex}
                                            colors={colors}
                                            onChange={function(color) {
                                                setHex(color.hex)
                                            }}
                                        />
                                    </>
                                </div>
                                <div class="form-group col-md-12 pl-3">
                                    <label>
                                        {i18n.t("tagModal.form.status")}
                                        <span className="text-danger ml-2">*</span>
                                    </label>
                                    <Field
                                        as={Switch}
                                        color="primary"
                                        name="status"
                                        checked={values.status}
                                        onChange={(e) => { setTag({ ...tag, status: !tag.status }); }}
                                    />
                                </div>
                            </div>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={submittingContent}
                                    variant="outlined"
                                >
                                    {i18n.t("tagModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={submittingContent}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {tagId
                                        ? `${i18n.t("tagModal.buttons.okEdit")}`
                                        : `${i18n.t("tagModal.buttons.okAdd")}`}
                                    {submittingContent && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div >
    );
};

export default TagModal;