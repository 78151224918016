import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import MessageIcon from '@material-ui/icons/Message';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import TimerIcon from '@material-ui/icons/Timer';
import ShareIcon from '@material-ui/icons/Share';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LockIcon from '@material-ui/icons/Lock';
import { Box, Divider, Drawer, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 425;

const useStyles = makeStyles((theme) => ({
  element: {
    padding: '10px',
    margin: '10px',
    position: 'relative',
    display: 'inline-table',
    height: '190px',
    width: '175px',
    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    cursor: 'pointer',
    wordBreak: 'break-word',
    userSelect: 'none',
    '& svg': {
      margin: '0 auto 10px auto',
      display: 'block',
      width: '30px',
      height: '30px',
    },
    '& h5': {
      display: 'block',
      position: 'relative',
      margin: '0 auto 10px auto',
      width: '100%',
      textAlign: 'center',
      fontSize: '1.2rem',
      fontWeight: '600',
    },
    '& h6': {
      display: 'block',
      position: 'relative',
      margin: '0 auto 10px auto',
      width: '100%',
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  title: {
    display: 'inline-block',
    padding: '10px',
    color: '#9e9e9e',
  },
  titleIcon: {
    display: 'inline-block',
    position: 'absolute',
    right: '0',
    top: '0',
  },
  div: {
    overflow: 'auto',
    padding: '10px',
    height: '100%',
    width: '100%',
    display: 'block',
    right: '0',
    top: '50px',
    maxWidth: `${drawerWidth}px`,
  },
  drawer: {
    width: `${drawerWidth}px`,
  },
  backdrop: {
    display: 'none',
  },
}));

const ElementMenu = ({ open, onClose, createNewNode }) => {
  const [drawerWidth, setDrawerWidth] = useState(425);
  const classes = useStyles({ drawerWidth });

  const onDragStart = (event, type, subtype) => {
    const data = JSON.stringify({ type, subtype });
    event.dataTransfer.setData('application/reactflow', data);
    console.log("Dragging:", data);
  };

  useEffect(() => {
    setDrawerWidth(open ? 425 : 0);
  }, [open]);
  
  const elements = [
    {
      title: 'Pergunta',
      description: 'Aguardar uma resposta do usuário, podendo armazenar em uma variável.',
      icon: <QuestionAnswerIcon />,
      type: 'conditional',
      subtype: 'cond',
    },
    {
      title: 'Transferir',
      description: 'Transferir atendimento para uma fila.',
      icon: <SyncAltIcon />,
      type: 'end',
      subtype: 'transfer',
    },
    {
      title: 'Finalizar',
      description: 'Finalizar atendimento.',
      icon: <DoneAllIcon />,
      type: 'end',
      subtype: 'end',
    },
    {
      title: 'Mensagem',
      description: 'Envio de mensagem de texto, mídia ou áudio ao usuário.',
      icon: <MessageIcon />,
      type: 'conditional',
      subtype: 'msg',
    },
    {
      title: 'Temporizador',
      description: 'Adicione um tempo de espera para seguir com o fluxo.',
      icon: <TimerIcon />,
      type: 'conditional',
      subtype: 'tmp',
    },
    {
      title: 'Validação',
      description: 'Adicione uma validação para alguma das variáveis globais.',
      icon: <CheckBoxIcon />,
      type: 'conditional',
      subtype: 'validate',
    },
    {
      title: 'Validação Composta',
      description: 'Adicione uma validação para alguma das variáveis locais.',
      icon: <CallSplitIcon />,
      type: 'conditional',
      subtype: 'validate_keywords',
    },
    {
      title: 'LGPD',
      description: 'Reforce o compromisso com a LGPD e a importância de o usuário ler e aceitar os termos antes de prosseguir.',
      icon: <LockIcon />,
      type: 'conditional',
      subtype: 'validate_lgpd',
    },
    {
      title: 'API',
      description: 'Adicione uma API ao fluxo para envio ou uso de variáveis.',
      icon: <ShareIcon />,
      type: 'conditional',
      subtype: 'api',
    },
  ];

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor="right"
      open={open == true ? true : false}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          onClose(false);
        }
      }}
      BackdropProps={{
        classes: {
            root: classes.backdrop,
        },
    }}
    ModalProps={{
        keepMounted: true,
    }}
    >
      <Box component="div">
        <Typography variant="h5" className={classes.title}>
          Elementos para o fluxo
        </Typography>
        <IconButton onClick={() => onClose(false)} className={classes.titleIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box component="div" className={classes.div}>
        {elements.map((element, index) => (
          <Box
            key={index}
            component="div"
            className={classes.element}
            onClick={() => {
              createNewNode(element.type, element.subtype);
              onClose(false);
            }}
            onDragStart={(event) => onDragStart(event, element.type, element.subtype)}
            draggable
          >
            {element.icon}
            <Typography variant="h5">{element.title}</Typography>
            <Typography variant="h6">{element.description}</Typography>
          </Box>
        ))}
      </Box>
    </Drawer>
  );
};

export default ElementMenu;
