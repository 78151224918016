import { Box, CircularProgress, Avatar, Typography, Tooltip, Chip, Grid } from "@material-ui/core";
import { IconButton, Popover } from '@mui/material';
import { useReducer, useEffect, useContext, useState, useMemo } from "react";
import socket from "../../../hooks/useSocket";
import { AuthContext } from "../../../context/Auth/AuthContext";
import toastError from "../../../errors/toastError";
import api from "../../../services/api";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../../translate/i18n";
import ContactModal from "../../ContactModal";
import ScheduleMessageListModal from "../../ScheduleMessageListModal";
import RenderChatRelatory from '../../RenderChatRelatory';
import ContactTransferModal from '../../ContactTransferModal';
import SingleSelect from "../../InputStyled/singleSelect";
import { Fragment } from "react";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MoreVert as MoreVertIcon, LoopOutlined as LoopOutlinedIcon, CreditCard as CreditCardIcon, HourglassEmptyOutlined as HourglassEmptyOutlinedIcon, PermContactCalendarOutlined as PermContactCalendarOutlinedIcon, AccessAlarm as AccessAlarmIcon, Chat as ChatIcon, SyncAlt as SyncAltIcon } from '@mui/icons-material';
import { FixedSizeList as List } from "react-window";
import { memo } from "react";


const sortByOrder = (list) => {
    if (!Array.isArray(list) || list.length == 0) return [];

    return list.slice().sort((a, b) => {
        const orderA = a.status?.order ?? a.name;
        const orderB = b.status?.order ?? b.name;
        return orderA - orderB;
    });
};


const statusSortByOrder = (list) => {
    if (!Array.isArray(list) || list.length == 0) return [];
    
    const resp = list.slice().sort((a, b) => {
        const orderA = a?.order;
        const orderB = b?.order;
        return orderA - orderB;
    });

    return resp;
};

const reducer = (state, action) => {

    switch (action.type) {
        case "LOAD_STATUS": {
            // Obtém o status a partir do payload da ação
            const status = action.payload;

            // Atualiza o estado existente com os valores do status, mantendo os não encontrados
            const updatedState = Array.isArray(state)
                ? state.map((s) => status.find((stat) => stat.id == s.id) || s)
                : [];

            // Identifica os novos status que não estão presentes no estado atual
            const newStatus = status.filter(
                (stat) => !state.some((s) => s.id == stat.id)
            );

            // Combina os estados atualizados e os novos, ordenando-os antes de retornar
            return statusSortByOrder([...updatedState, ...newStatus]);
        }

        case "UPDATE_STATUS": {
            const stat = action.payload;
            const updatedState = state.some((s) => s.id == stat.id)
                ? state.map((s) => (s.id == stat.id ? stat : s))
                : [stat, ...state];

            return statusSortByOrder(updatedState);
        }

        case "DELETE_STATUS": {
            const statusId = action.payload;
            const updatedState = state.filter((s) => s.id != statusId);

            return statusSortByOrder(updatedState);
        }

        case "RESET":
            return [];

        default:
            return state;
    }
};

const reducerContacts = (state, action) => {

    if (action.type == "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];
        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id == contact.id);
            if (contactIndex != -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return sortByOrder([...state, ...newContacts]);
    }

    if (action.type == "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id == contact.id);
        if (contactIndex != -1) {
            state[contactIndex] = Object.assign(state[contactIndex], contact);
            return sortByOrder([...state]);
        } else {
            const response = [...state, contact];
            return sortByOrder(response);
        }
    }

    if (action.type == "DELETE_CONTACTS") {
        const contactId = action.payload;

        const contactIndex = state.findIndex((c) => c.id == contactId);
        if (contactIndex != -1) {
            state.splice(contactIndex, 1);
        }
        return sortByOrder([...state]);
    }

    if (action.type == "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    column: {
        width: '350px',
        padding: '45px 0px 10px 0px',
        margin: '0',
        height: '100%',
        display: 'block',
        position: 'relative',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)',
        "&>div": {
            backgroundColor: 'transparent!important',
            margin: '0!important',
            padding: '0 10px!important',
            borderRadius: '0px!important',
            height: 'calc(100% - 5px)',
            width: '350px',
            overflowY: 'hidden'
        }
    },
    columnHeader: {
        background: '#0b708c',
        color: '#ffffff',
        display: 'flex',
        width: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '40px',
        borderRadius: '10px 10px 0 0',
        padding: '10px',
        textTransform: 'capitalize',
        fontSize: '1.1rem',
        fontWeight: '700',
        '&>label': {
            height: '100%',
            width: '200px',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '&>span': {
            textAlign: 'right',
            display: 'inline-block',
            width: '100px',
            height: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '&>button': {
            top: '2px',
            width: '15px',
            height: '15px',
            color: '#ffffff',
            left: '5px'
        }
    },
    columnItem: {
        margin: '0 0 15px 0',
        backgroundColor: 'white',
        borderRadius: 3,
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #e0e0e0',
        height: '120px',
        position: 'relative',
    },
    header: {
        top: '0',
        position: 'absolute',
        width: '100%',
        paddingLeft: '15px',
        padding: '5px',
        height: '50px',
        '&>h6': {
            display: 'inline-block',
            width: '200px',
            paddingLeft: '5px',
            position: 'absolute',
            height: '25px',
            lineHeight: '25px',
            fontSize: '1.2rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '&>label': {
            display: 'inline-block',
            width: '50px',
            left: '260px',
            paddingLeft: '5px',
            position: 'absolute',
            height: '25px',
            lineHeight: '25px',
            color: '#616161',
            fonteWeight: '900',
            fontSize: '0.9rem',
        },
    },
    profilePicture: {
        height: '40px',
        width: '40px',
        display: 'inline-block',
        '&>svg': {
            height: '100%',
            width: '100%'
        }
    },
    tags: {
        position: 'absolute',
        top: '30px',
        display: 'inline-block',
        width: 'calc(100% - 45px)',
        left: '45px',
        height: '20px',
        paddingLeft: '10px',
        '&>div': {
            display: 'inline-block',
            width: '30px',
            height: '15px',
        }
    },
    productsAndServices: {
        position: 'absolute',
        width: '100%',
        height: '25px',
        top: '65px',
        padding: '5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: '15px',
    },
    currencyValues: {
        position: 'absolute',
        width: '100%',
        height: '25px',
        top: '45px',
        padding: '5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#757575',
        fontSize: '1rem',
        paddingLeft: '15px',
        '&>span': {
            marginRight: '5px'
        }
    },
    footer: {
        position: 'absolute',
        width: '100%',
        height: '40px',
        top: '73px',
        padding: '5px',
        paddingLeft: '15px',
        "&>button": {
            backgroundColor: '#eeeeee',
            marginRight: '5px'
        },
        "&>button:hover": {
            backgroundColor: '#0b708c',
            color: '#ffffff'
        }
    },
    icon: {
        width: '30px',
        height: '30px',
        margin: '0 2px',
        borderRadius: '100%',
        background: '#e0e0e0',
        color: '#0B708C',
        "&:hover": {
            background: '#bdbdbd'
        },
        "&>svg": {
            width: '20px',
            height: '20px'
        },
    },
    shortName: {
        width: '30px',
        height: '30px',
        background: '#0B708C',
        borderRadius: '100%',
        float: 'right',
        textAlign: 'center',
        paddingTop: '6px',
        color: '#ffffff',
        cursor: 'pointer'
    },
    queue: {
        position: 'absolute',
        left: '0',
        height: '100%',
        minHeight: '50px',
        width: '10px',
        background: '#bdbdbd',
        cursor: 'pointer!important',
        zIndex: '2',
    },
    customPaper: {
        padding: '10px!important',
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
        width: '250px!important',
        borderRadius: '0!important'
    },
}));

export default function Kanban({ refresh, ...props }) {

    const [status, dispatch] = useReducer(reducer, []);
    const [contacts, dispatchContacts] = useReducer(reducerContacts, []);
    const [loading, setLoading] = useState(false);
    const [contactModal, setContactModal] = useState(null);
    const [scheduleModal, setScheduleModal] = useState({ contactId: null, ticketId: null, whatsappId: null });
    const [messageHistoryModal, setMessageHistoryModal] = useState(null);
    const [transferTicketModal, setTransferTicketModal] = useState({ contact: null, ticket: null });
    const { user } = useContext(AuthContext);
    const adminId = user.adminId;
    const classes = useStyles();
    const [anchorEls, setAnchorEls] = useState({});
    const currencyFilter = [
        { key: 'recurringValue', value: 'Valores Recorrentes', disabled: false },
        { key: 'singleValue', value: 'Valores Únicos', disabled: false },
        { key: 'contractValue', value: 'Valores dos Contratos', disabled: false },
    ];
    const [selectedCurrencyFilter, setSelectedCurrencyFilter] = useState([]);

    useEffect(() => {

        const customerRelationshipStatusEvent = `customerRelationshipStatus-${adminId}`;
        const customerRelationshipStatusListener = async (data) => {

            if (data.action == "update" || data.action == "create") {
                dispatch({ type: "UPDATE_STATUS", payload: data.status });
            }

            if (data.action == "delete") {
                dispatch({
                    type: "DELETE_STATUS",
                    payload: +data.statusId,
                });
            }
        };

        const contactEvent = `contact-${adminId}`;
        const contactListener = async (data) => {
            if (data.action == "update" || data.action == "create") {
                dispatchContacts({ type: "UPDATE_CONTACTS", payload: data.contact });
            }
            if (data.action == "delete") {
                dispatchContacts({ type: "DELETE_CONTACTS", payload: +data.contactId });
            }
        };

        const ticketEvent = `ticket-${adminId}`;
        const ticketListener = async (data) => {
            if (data.action == "update") {
                // verificar se o ticket está aberto ou aguardando e possuí contato
                const { ticket } = data;

                if (ticket?.Contact && (ticket.status == 'open' || ticket.status == 'pending')) {
                    // se não existir resgatar informação completa do contato para atualizar
                    (async () => {
                        try {
                            const { data } = await api.get(`/contacts/${ticket.Contact.id}`);
                            dispatchContacts({ type: "UPDATE_CONTACTS", payload: { ...data, Ticket: ticket } });
                        } catch (err) {
                            console.error({ err });
                            toastError(err);
                        }
                    })();
                }
            } else if (data.action == 'delete') {
                const { contactId } = data;
                if (contactId) {
                    // deletar contato somente se for da coluna sem status
                    dispatchContacts({ type: "DELETE_CONTACTS", payload: contactId });
                }
            }
        };

        socket.on(customerRelationshipStatusEvent, customerRelationshipStatusListener);
        socket.on(contactEvent, contactListener);
        socket.on(ticketEvent, ticketListener);

        return () => {
            socket.off(customerRelationshipStatusEvent, customerRelationshipStatusListener);
            socket.off(contactEvent, contactListener);
            socket.off(ticketEvent, ticketListener);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            (async () => {
                try {
                    const { data } = await api.get("/customerRelationship/status/list");
                    dispatch({ type: "LOAD_STATUS", payload: data.status });

                    const { data: cData } = await api.get("/contacts/crmList");
                    dispatchContacts({ type: "LOAD_CONTACTS", payload: cData.contacts });

                    if (data.status && Array.isArray(data.status)) {
                        setSelectedCurrencyFilter(prevFilters => {
                            const newFilters = [...prevFilters];
                                   
                            data.status.forEach(s => {
                                const exists = newFilters.some(cf => cf.statusId == s.id);
                                if (!exists) {
                                    newFilters.push({ statusId: s.id, selected: 'recurringValue' });
                                }
                            });
                    
                            return newFilters;
                        });
                    }

                    setLoading(false);
                } catch (err) {
                    console.error({ err });
                    toastError(err);
                }
            })();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ , refresh]);

    // Função chamada quando um item é movido
    const onDragEnd = (result) => {

        // Se o item não foi movido para um novo destino, retorne
        if (!result.destination) return;

        // verificar se está movendo para outra coluna
        if (result.destination.droppableId != result.source.droppableId) {
            handleStatusId(result.destination.droppableId, result.draggableId);
            
            let ctcTmp = contacts.find(c => c.id == result.draggableId);
            if (ctcTmp) dispatchContacts({ type: "UPDATE_CONTACTS", payload: { ...ctcTmp, status: { statusId: result.destination.droppableId, contactId: result.draggableId } } });

            return;
        } else {
            // Reordene a lista de itens
            handleCardOrder(result.source.droppableId, result.draggableId, result.destination.index);
            let ctcTmp = contacts.find(c => c.id == result.draggableId);
            if (ctcTmp) dispatchContacts({ type: "UPDATE_CONTACTS", payload: { ...ctcTmp, status: { statusId: result.source.droppableId, contactId: result.draggableId, order: result.destination.index } } });
            return;
        }
    };

    // modal de informações de contato
    const handleContactInfoOpen = (contactId) => {
        setContactModal(contactId);
    }

    const handleContactInfoClose = () => {
        setContactModal(null)
    }

    // Modal de agendamento de mensagens 
    const handleScheduleMessageOpen = (contactId, ticketId, whatsappId) => {
        setScheduleModal({ contactId, ticketId, whatsappId });
    };

    const handleScheduleMessageClosed = () => {
        setScheduleModal({ contactId: null, ticketId: null, whatsappId: null });
    };

    // Modal de histórico de atendimento
    const handleMessageHistoryOpen = (ticketId) => {
        setMessageHistoryModal(ticketId);
    }

    const handleMessageHistoryClose = () => {
        setMessageHistoryModal(null);
    }

    // Modal de transferencia de atendimento
    const handleTransferOpen = (contact, ticket) => {
        setTransferTicketModal({ contact, ticket });
    }

    const handleTransferClose = () => {
        setTransferTicketModal({ contact: null, ticket: null });
    }

    // menu de exibição de valores
    const handleClick = (event, id) => {
        setAnchorEls(prev => ({ ...prev, [id]: event.currentTarget }));
    };
    const handleClose = (id) => {
        setAnchorEls(prev => ({ ...prev, [id]: null }));
    };

    const formatNumber = (input) => {
        // Converte o input para string, se não for
        let str = input.toString();
    
        // Separa a parte inteira e decimal
        let [integerPart, decimalPart] = str.split(',');
    
        // Adiciona o separador de milhar na parte inteira
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    
        // Junta a parte formatada com a decimal
        return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
    }

    const sumDecimalValues = (values) => {
        const sum = values.reduce((total, value) => {
            // Substitui ',' por '.' e converte para número
            let numericValue = value.replaceAll('.','');
            numericValue = parseFloat(numericValue.replace(',', '.'));
            return total + numericValue;
        }, 0);

        // Formata o resultado de volta para o formato decimal com vírgula
        return formatNumber(sum.toFixed(2).replace('.', ','));
    }

    // Função para calcular os totais de valores.
    const getTotals = (contacts, keys) => {
        return keys.reduce((acc, key) => {
            acc[key] = sumDecimalValues(
                contacts.map(contact => contact.extraInfo.find(ei => ei.name == key)?.value || '0,00')
            );
            return acc;
        }, {});
    };

    const handleSelectedCurrencyFilter = (currencyFilter, statusId) => {

        setSelectedCurrencyFilter(prevFilters => {
            const newFilters = [...prevFilters];
    
            const idx = newFilters.findIndex(cf => cf.statusId == statusId);
            if (idx != -1) newFilters[idx].selected = currencyFilter;
    
            return newFilters;
        });
    }

    const handleStatusId = async (statusId, contactId) => {
		try {
            await api.post("/contacts/customerRelationship/status/update", { statusId, contactId });
		} catch (err) {
			console.error({ err });
			toastError(err);
		}
	};

    const handleCardOrder = async (statusId, contactId, order) => {
		try {
            await api.post("/contacts/customerRelationship/statusOrder/update", { statusId, contactId, order });
		} catch (err) {
			console.error({ err });
			toastError(err);
		}
	};

    // Subcomponente: ContactCard
    const ContactCard = ({ contact }) => (
        <Box className={classes.columnItem}>
            <Tooltip title={contact?.Ticket?.Queue?.name || 'Sem Fila'}>
                <Box
                    className={classes.queue}
                    component="div"
                    style={{ background: contact?.Ticket?.Queue?.color }}
                />
            </Tooltip>
            <Box className={classes.header} component="div">
                <Avatar src={contact.profilePicUrl} className={classes.profilePicture} />
                <Typography variant="h6">
                    {contact.name}
                </Typography>
                {contact?.Ticket?.id && (
                    <Typography component="label" key={`ticket-${contact.Ticket.id}`}>
                        #{contact.Ticket.id}
                    </Typography>
                )}
            </Box>
            <Box className={classes.tags}>
                {renderTags(contact.tagsId, contact?.id)}
            </Box>
            <Box className={classes.currencyValues}>
                {renderValues(contact)}
            </Box>
            <Box className={classes.footer}>
                {renderActions(contact)}
            </Box>
        </Box>
    );    

    // Função para renderizar tags
    const renderTags = (tags, contactId) => (
        Array.isArray(tags) ? tags.map(tag => (
            <Tooltip 
                arrow 
                title={tag.name || "Não identificado"} 
                key={`tags-contact-${contactId}-${tag.id}`}
            >
                <Chip size="small" style={{ backgroundColor: tag.color, margin: '0 3px' }} />
            </Tooltip>
        )) : null
    );

    // Função para renderizar valores
    const renderValues = contact => {
        const icons = [
            { icon: <LoopOutlinedIcon />, label: 'recurringValue', tooltip: 'Valores Recorrentes' },
            { icon: <CreditCardIcon />, label: 'singleValue', tooltip: 'Valores Únicos' },
            { icon: <HourglassEmptyOutlinedIcon />, label: 'contractTime', tooltip: 'Prazo Contratual' }
        ];

        return icons.map(({ icon, label, tooltip }, index) => (
            <Tooltip arrow title={tooltip} key={`valuesForContact-${contact?.id}-${index}`}>
                <Box component="span">
                    {icon} {label == 'contractTime' ? `${contact.extraInfo.find(e => e.name == label)?.value || '0'} Mes(es)` : `R$ ${formatNumber(contact.extraInfo.find(e => e.name == label)?.value || '0,00')}`}
                </Box>
            </Tooltip>
        ));
    };

    // Função para renderizar produtos e serviços
    const renderProducts = products =>
        Array.isArray(products) && products.length > 0
            ? products.map(ps => ps['CustomerRelationshipProductAndService.name']).join(", ")
            : 'nenhum registro.';

    // Função para renderizar ações
    const ActionButton = ({ title, Icon, onClick, disabled }) => (
        <Tooltip title={title}>
            <IconButton disabled={disabled} onClick={onClick} component="span">
                <Icon />
            </IconButton>
        </Tooltip>
    );
   
    const renderActions = (contact) => {
        if (!contact) return null;

        const { id, Ticket } = contact;
        const ticketId = Ticket?.id;
        const whatsappId = Ticket?.whatsappId;
        const isTicketClosed = Ticket?.status == 'closed';

        return (
            <Fragment
                key={`actionsForContact-${contact?.id}`}
            >
                <ActionButton
                    title="Informações de Contato"
                    Icon={PermContactCalendarOutlinedIcon}
                    onClick={() => handleContactInfoOpen(id)}
                    disabled={!id}
                />
                <ActionButton
                    title="Agendar Mensagem"
                    Icon={AccessAlarmIcon}
                    onClick={() => handleScheduleMessageOpen(id, ticketId, whatsappId)}
                    disabled={!Ticket}
                />
                <ActionButton
                    title="Histórico de Mensagens"
                    Icon={ChatIcon}
                    onClick={() => handleMessageHistoryOpen(ticketId)}
                    disabled={!Ticket}
                />
                <ActionButton
                    title="Transferir Atendimento"
                    Icon={SyncAltIcon}
                    onClick={() => handleTransferOpen(contact, Ticket)}
                    disabled={!Ticket || isTicketClosed}
                />
            </Fragment>
        );
    };

    const filteredContactsByStatus = useMemo(() => {
        return status.reduce((acc, s) => {
            acc[s.id] = contacts.filter(contact => 
                String(s.name).toLowerCase() !== 'sem status' 
                    ? contact?.status?.statusId === s.id 
                    : !contact.status || contact?.status?.statusId === s.id
            );
            return acc;
        }, {});
    }, [contacts, status]);

    const totalsByStatus = useMemo(() => {
        return status.reduce((acc, s) => {
            acc[s.id] = getTotals(filteredContactsByStatus[s.id], ['recurringValue', 'singleValue', 'contractValue']);
            return acc;
        }, {});
    }, [filteredContactsByStatus]);

    return (
        <Box {...props}>
            {
                loading == true
                    ?
                    <CircularProgress color="primary" style={{ margin: "0 auto", display: "block" }} />
                    :
                    <DragDropContext
                        onDragEnd={onDragEnd}
                    >
                        {status && status.map((s, index) => {
                            
                            const totals = totalsByStatus[s.id];
                            const sCurrencyFilter = selectedCurrencyFilter.find(cf => cf.statusId == s.id)?.selected || '';
                            const anchorEl = anchorEls[s.id] || null;
                            return (
                                <Droppable 
                                    droppableId={String(s.id)}
                                    type="COLUMN" 
                                    direction="vertical"
                                    key={`droppable-${s.id}`}
                                    renderClone={(provided, snapshot, rubric) => {
                                        const sourceColumn = filteredContactsByStatus[rubric.source.droppableId] || [];
                                        const contact = sourceColumn[rubric.source.index];
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    opacity: snapshot.isDragging ? 0.8 : 1,
                                                }}
                                            >
                                                <ContactCard contact={contact} />
                                            </div>
                                        );
                                    }}
                                >
                                    {provided => (
                                        <Box 
                                            {...provided.droppableProps} 
                                            ref={provided.innerRef}
                                            className={classes.column}
                                            style={{ marginRight: index < (status.length - 1) ? '10px' : '0' }}
                                        >
                                            <Box component="header" className={classes.columnHeader}>
                                                <Box component="label">
                                                    {s.name} ({filteredContactsByStatus[s.id].length})
                                                </Box>
                                                <Tooltip
                                                    arrow
                                                    className={classes.tooltip}
                                                    title={
                                                        <>
                                                            <Typography fontSize={20}>Valores Recorrentes: R$ {totals.recurringValue}</Typography>
                                                            <Typography fontSize={20}>Valores Únicos: R$ {totals.singleValue}</Typography>
                                                            <Typography fontSize={20}>Valores dos Contratos: R$ {totals.contractValue}</Typography>
                                                        </>
                                                    }
                                                >
                                                    <Box component="span" style={{ float: 'right' }}>
                                                        R$ {totals[sCurrencyFilter]}
                                                    </Box>
                                                </Tooltip>
                                                <IconButton
                                                    aria-label="more-icon"
                                                    onClick={(event) => handleClick(event, s.id)}
                                                    id="more-icon"
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Popover
                                                    id={`menu-filter-${s.id}`}
                                                    key={`menu-filter-${s.id}`}
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={() => handleClose(s.id)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    classes={{ paper: classes.customPaper }}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} mb={2}>
                                                            <SingleSelect
                                                                key={`currencyFilter${s.id}`}
                                                                id={`currencyFilter${s.id}`}
                                                                title={<>Exibir informação de <b>Valor Total</b> de:</>}
                                                                displayText="Selecionar Filtro"
                                                                name="currencyFilter"
                                                                options={currencyFilter}
                                                                onSelected={(id) => { 
                                                                    handleSelectedCurrencyFilter(id, s.id);
                                                                }}
                                                                selected={sCurrencyFilter}
                                                                closeOnSelect
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Popover>
                                            </Box>

                                            <Box>
                                                <List
                                                    height={100}
                                                    itemCount={filteredContactsByStatus[s.id].length}
                                                    itemSize={135} // Altura de cada item
                                                    width="100%"
                                                    style={{ height: '100%' }}
                                                >
                                                {({ index, style }) => {
                                                        const contact = filteredContactsByStatus[s.id][index];
                                                        if (!contact) return;
                                                        return (
                                                            <Box style={style}>
                                                                <Draggable
                                                                    key={`draggable-${contact.id}`}
                                                                    draggableId={String(contact.id)}
                                                                    index={index}
                                                                >
                                                                    {provided => (
                                                                        <Box 
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <ContactCard contact={contact} />
                                                                        </Box>
                                                                    )}
                                                                </Draggable>
                                                            </Box>
                                                        );
                                                    }}
                                                </List>
                                            </Box>
                                        </Box>
                                    )}
                                </Droppable>
                            );
                        })}
                    </DragDropContext>
            }
            <ContactModal
                open={contactModal != null ? true : false}
                onClose={handleContactInfoClose}
                aria-labelledby="form-dialog-title"
                contactId={contactModal}
            />
            <ScheduleMessageListModal
                ticketId={scheduleModal?.ticketId || null}
                contactId={scheduleModal?.contactId || null}
                connectionId={scheduleModal?.whatsappId || null}
                open={scheduleModal?.ticketId && scheduleModal?.contactId && scheduleModal?.whatsappId ? true : false}
                onClose={handleScheduleMessageClosed}
            />
            <RenderChatRelatory
                title={`#${messageHistoryModal}`}
                open={messageHistoryModal != null}
                onClose={e => { handleMessageHistoryClose() }}
                ticketId={messageHistoryModal}
            />
            <ContactTransferModal
                open={transferTicketModal?.contact && transferTicketModal?.ticket ? true : false}
                handleDrawerClose={handleTransferClose}
                contact={transferTicketModal?.contact}
                ticket={transferTicketModal?.ticket}
                userTranfer={user.id}
                isDialog={true}
            />
        </Box>
    );
}