import { Box, ClickAwayListener, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Grid, IconButton, Typography, Tooltip, Button, CircularProgress, Switch, FormGroup, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { useContext, useRef, useState, useEffect } from "react";
import toastError from "../../../../errors/toastError";
import { i18n } from "../../../../translate/i18n";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import InputStyled from "../../../InputStyled";
import DatePickerBootstrap from "../../../DatePickerBootstrap";
import GroupedInputStyled from "../../../GroupedInputStyled";
import excelContacts from "../../../../assets/xlsx/download.xlsx";
import api from "../../../../services/api";
import MultiSelectChip from "../../../MultiSelectChip";
import FileUploader from "../../../FileUploader";
import { toast } from "react-toastify";
import PhoneValidator from "../../../../helpers/PhoneValidator";
import noSpecialCharacter from "../../../../helpers/noSpecialCharacter";
import { Alert } from "@mui/material";
import CheckUrl from "../../../../helpers/CheckUrl";
import MicRecorder from 'mic-recorder-to-mp3';
import BackupIcon from '@material-ui/icons/Backup';
import musicSvg from "../../../../assets/svg/music.svg";
import pdfSvg from "../../../../assets/svg/pdf.svg";
import upload from "../../../../assets/svg/upload.svg";
import xlsxSvg from "../../../../assets/svg/xlsx.svg";
import csvSvg from "../../../../assets/svg/csv.svg";
import headsetSvg from "../../../../assets/svg/headset.svg";
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import StopIcon from '@material-ui/icons/Stop';
import MoodIcon from "@material-ui/icons/Mood";
import HelpIcon from '@material-ui/icons/Help';
import GetExtensionFromBase64String from "../../../../helpers/GetExtensionFromBase64String";
import * as Yup from "yup";
import EmojiDataPicker from "../../../EmojiDataPicker";
import { makeStyles, useTheme } from "@material-ui/core";

const XLSX = require('xlsx');

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paperWidthMd": {
            maxWidth: "700px"
        }
    },
    switch: {
        paddingLeft: ".25rem !important",
        float: "right"
    },
    emojiBox: {
        position: "absolute",
        zIndex: '100',
        borderTop: "1px solid #e8e8e8",
    },
    customButton: {
        padding: "7px",
        float: "right",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        "& svg": {
            color: theme.palette.primary.contrastText,
            marginRight: '5px'
        },
    },
}));

const ChatbotCampaignModal = ({ open, onClose, campaignProps, onSave }) => {

    const formatInitialDate = () => {
        const currentDate = new Date();
        const day = (currentDate.getDate().toString().padStart(2, '0'));
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = (currentDate.getFullYear());
        const formattedDate = `${year}-${month}-${day}`
        return formattedDate;
    }

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const weekDays = [{ name: 'dom', label: 'Domingo' }, { name: 'seg', label: 'Segunda' }, { name: 'ter', label: 'Terça' }, { name: 'qua', label: 'Quarta' }, { name: 'qui', label: 'Quinta' }, { name: 'sex', label: 'Sexta' }, { name: 'sab', label: 'Sabado' }];
    const initialState = {
        id: '',
        name: '',
        holiday: true,
        start_date: '',
        start_hours: '09',
        start_minutes: '00',
        end_date: '',
        end_hours: '18',
        end_minutes: '00',
        message: '',
        weekDays: Array.isArray(weekDays) ? weekDays.filter(w => w.name != 'sab' && w.name != 'dom').map(w => w.name) : [],
        moveTo: 'pending',
        sendBlackListMessage: true,
        blackListMessage: 'Caso não queira mais receber nossas notificações, clique no link abaixo:',
        status: ''
    };
    const [campaign, setCampaign] = useState(initialState);
    const [weekDayBlocked, setWeekDayBlocked] = useState("");
    const [selectedTagsIds, setSelectedTagsIds] = useState([]);
    const [messageVariable, setMessageVariable] = useState([]);
    const [countContacts, setCountContact] = useState(null);
    const VALID_EXTENSIONS = ['xlsx', 'csv'];
    const [timeEstimate, setTimeEstimate] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [contactsError, setContactsError] = useState([]);
    const [media64archive, setMedia64archive] = useState("");
    const [blockedMessage, setBlockedMessage] = useState('enable');
    const [showEmoji, setShowEmoji] = useState(false);
    const [showBlackListEmoji, setShowBlackListEmoji] = useState(false);
    const [campaignStarted, setCampaignStarted] = useState(false);
    const padTo2Digits = num => String(num).padStart(2, '0');
    const recorder = new MicRecorder({ bitRate: 128 });
    const greetingRef = useRef();
    const [contactsFromTags, setContactsFromTags] = useState(true);
    const [addVariables, setAddVariables] = useState(false);
    const theme = useTheme(); // Hook para acessar o tema v4

    useEffect(() => {
        let dateFilled = campaignProps?.start_date && campaignProps?.start_hours && campaignProps?.start_minutes && campaignProps?.end_date && campaignProps?.end_hours && campaignProps?.end_minutes ? true : false;

        if (open == true) {
            if (dateFilled != true) checkDate({ target: { name: 'start_date', value: formatInitialDate() } });
            else checkDate({ target: { name: 'start_date', value: campaignProps.start_date } })
        }

        if (open == false || !campaignProps) return;

        // verificar se a campanha enviada possuí todas as informações para carregar no modal
        let campaignTmp = campaign;
        for (let key of Object.keys(initialState)) {
            if (campaignProps[key] != undefined) campaignTmp[key] = campaignProps[key];
            else campaignTmp[key] = initialState[key];
        }
        setCampaign(campaignTmp);

        // setar contatos
        if (campaignProps['contacts'] && campaignProps['contacts'].length > 0) {
            setTimeout(() => {
                initUIForProcessing(false);

                setContacts(campaignProps['contacts']);
                setContactsError([]);
                updateUIPostProcessing(campaignProps['contacts'], []);

                let columns = [];
                for (let key of Object.keys(campaignProps['contacts'][0])) {
                    if (key == 'number') continue;
                    if (key == 'name') columns.push('nome');
                    else columns.push(key);
                }
                setMessageVariable(columns);
                updateProgress(campaignProps['contacts'].length, campaignProps['contacts'].length);
            }, 1000);
        }

        if (campaignProps['selectedTagsIds'] && campaignProps['selectedTagsIds'].length > 0) {
            setSelectedTagsIds(campaignProps['selectedTagsIds']);
            setMessageVariable(['nome']);
            countContactsByTags();
            setContactsFromTags(true);
        } else {
            setContactsFromTags(false);
        }

        if (campaignProps['media64archive']) {
            setTimeout(() => {

                let b64 = "";

                // Seletores de elementos DOM
                const imgUploadedUser = document.querySelector('#imgUploaded');
                const audioPlay = document.querySelector('#playerAudioUser');
                const playlist = document.querySelector('#playlist');

                b64 = `data:${campaignProps['media64archive'].split('data:')[1]}`;

                // obetendo extensão do arquivo
                const getExtension = GetExtensionFromBase64String(b64);
                if (getExtension.status == false) {
                    toast.error(getExtension.response);
                    return;
                }

                const ext = getExtension.response;

                if (ext == 'jpg' || ext == 'png' || ext == 'svg' || ext == 'jpg' || ext == 'webp' || ext == 'avif' || ext == 'bmp' || ext == 'gif' || ext == 'ico') {
                    displayImage(imgUploadedUser, b64, "Imagem enviada pelo usuário");
                } else if (ext == 'mp3' || ext == 'oga' || ext == 'opus' || ext == 'aac' || ext == 'mid' || ext == 'wav' || ext == 'weba' || ext == '3gp' || ext == '3g2') {
                    audioPlay.src = b64;
                    displayImage(imgUploadedUser, musicSvg, "Áudio enviado pelo usuário");
                    playlist.style.display = "";
                } else if (ext == 'pdf') {
                    displayImage(imgUploadedUser, pdfSvg, "PDF enviado pelo usuário");
                } else if (ext == 'xlsx') {
                    displayImage(imgUploadedUser, xlsxSvg, "XLSX enviado pelo usuário");
                } else if (ext == 'csv') {
                    displayImage(imgUploadedUser, csvSvg, "CSV enviado pelo usuário");
                } else {
                    displayImage(imgUploadedUser, upload, "Arquivo enviado pelo usuário");
                }

                if (b64.length < 15) {
                    toast.error('Arquivo inválido para upload.');
                    return;
                }

                if (campaignProps['mediaName']) setMedia64archive(`name(${campaignProps['mediaName']})${campaignProps['media64archive']}`);
                else setMedia64archive(`${campaignProps['media64archive']}`);
            }, 1000);
        }

        // verificar se a campanha já foi iniciada, se já disparou algum
        const fetchCampaignStarted = async () => {
            try {
                if (!campaignProps?.id) return;
                const { data } = await api.get(`/chatbotCheckStarted/${campaignProps.id}`);
                setCampaignStarted(data?.status == false ? false : true);
            } catch (err) {
                console.error(err);
                setCampaignStarted(true);
            }
        }
        fetchCampaignStarted();

    }, [open]);

    const handleClose = (fromSave = false) => {
        // resetar informações ao fechar modal
        setCampaign(initialState);
        setWeekDayBlocked("");
        setSelectedTagsIds([]);
        setMessageVariable([]);
        setCountContact(null);
        setTimeEstimate(null);
        setContacts([]);
        setContactsError([]);
        setMedia64archive("");
        setBlockedMessage('enable');
        setShowEmoji(false);
        setShowBlackListEmoji(false);
        setCampaignStarted(false);
        setContactsFromTags(true);
        setAddVariables(false);

        if (fromSave == true) onClose({ messageVariable });
        else onClose();
    }

    let CampaignSchema = Yup.object().shape({
        start_date: Yup.string()
            .required("Insira uma data inicial."),
        start_hours: Yup.string()
            .required("Insira uma hora de inicio."),
        start_minutes: Yup.string()
            .required("Insira os minutos de inicio."),
        end_date: Yup.string()
            .required("Insira uma data final."),
        end_hours: Yup.string()
            .required("Insira uma hora final."),
        end_minutes: Yup.string()
            .required("Insira os minutos de fim."),
        weekDays: Yup.array()
            .test(
                "required",
                "Escolha ao menos um dia da semana para efetuar o envio.",
                value => {
                    return !value || value.length <= 0 ? false : true;
                }
            ),
    });
    if (CampaignSchema['_nodes']) CampaignSchema['_nodes'] = CampaignSchema['_nodes'].reverse();

    const handleSave = async (values) => {
        // validar se todos os campos foram preenchidos corretamente
        let validated = false;
        try {
            await CampaignSchema.validate(values);
            // etiquetas ou mailing
            if ((!selectedTagsIds || selectedTagsIds.length == 0) && (!contacts || contacts.length == 0)) throw new Error('Selecione etiquetas ou utilize anexe o modelo de contatos.');
            // arquivos ou mensagem
            if ((!values.message || String(values.message).trim() == '') && !media64archive) throw new Error('Insira uma mensagem, arquivo ou aúdio a ser enviado.');
            // link com mensagem para descadastrar
            if (values.sendBlackListMessage == true && (!values.blackListMessage || String(values.blackListMessage).trim() == '')) throw new Error('Insira uma mensagem para o descadastramento do recebimento de campanhas.');
            validated = true;
        } catch (err) {
            console.error({err});
            if (validated == false) toastError({ response: { data: { error: err?.message ? err.message : 'INTERNAL_ERROR' } } });
            else { toastError({ response: { data: { error: 'INTERNAL_ERROR' } } }); console.error(err); }
            return;
        }

        // montar objeto da campanha para salvar ou voltar para editar
        const response = { ...values };
        response.contacts = contacts;
        response.media64archive = media64archive;
        response.selectedTagsIds = selectedTagsIds;

        onSave(response);
        handleClose(true);
    }

    const getDayOfWeek = (dateString) => {
        const daysOfWeek = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
        const date = new Date(dateString + 'T00:00:00Z');
        const dayOfWeekNumber = date.getUTCDay();
        return daysOfWeek[dayOfWeekNumber];
    };

    const openDatePicker = (input) => {
        var inputDate = document.getElementById(input)
        if (!inputDate.disabled) {
            inputDate.showPicker()
        }
    }

    const checkDate = (e) => {
        var value = e.target.value;
        var name = e.target.name;

        if (name == 'start_date') {
            const currentDay = getDayOfWeek(value);
            let currentWeekDays = campaign.weekDays;
            if (!currentWeekDays.includes(currentDay)) currentWeekDays.push(currentDay);
            setWeekDayBlocked(currentDay);
            setCampaign({ ...campaign, start_date: value, end_date: value, weekDays: currentWeekDays });

        } else {
            setCampaign({ ...campaign, end_date: value });
        }
    }

    const format = (e) => {
        var value = e.target.value.replace(/[^0-9]/g, '');
        var name = e.target.name;

        if (name === 'start_hours') {
            if (value > 19) value = 19;
        }

        if (name === 'end_hours') {
            if (value > 20) value = 20;
        }

        if (name === 'interval_hours') {
            if (value > 23) value = 23;
        }

        if (name === 'start_minutes' || name === 'end_minutes' || name === 'interval_minutes') {
            if (value > 59) value = 59;
        }
        setCampaign({ ...campaign, [name]: value });

    }

    const minFormat = (e) => {
        var value = e.target.value.replace(/[^0-9]/g, '');
        var name = e.target.name;

        if (name === 'start_hours') {
            if (value < 8) value = 8;
        }

        if (name === 'end_hours') {
            if (value < 9) value = 9;
        }

        value = ('00' + value).slice(-2);

        setCampaign({ ...campaign, [name]: value });
    }

    const handleSelect = (day) => {
        const isDaySelected = campaign.weekDays.includes(day);
        setCampaign({ ...campaign, weekDays: isDaySelected ? campaign.weekDays.filter(selectedDay => selectedDay != day) : [...campaign.weekDays, day] });
    };

    const checkIsCkeched = (day, selected) => day === selected;

    const countContactsByTags = async () => {
        if (!selectedTagsIds || !Array.isArray(selectedTagsIds) || selectedTagsIds.length == 0) {
            setCountContact(null);
            return;
        }

        setLoading(true);

        try {
            const { data } = await api.post("/countContactsByTags", { tagsId: selectedTagsIds });
            if (data.qty) {
                setCountContact(data.qty);
            }
            else {
                setCountContact(null);
            }
        } catch (err) {
            console.error({err});
            document.querySelector("#inputMessageCampaign").value = "";
            toastError(err);
        } finally {
            setLoading(false);
        }
    }

    const getColumnsFromFile = (file) => {
        // Pegar colunas adicionar na tabela
        var listColumnNames = file.SheetNames;
        let columnNames = [];
        for (var i = 0; i < listColumnNames.length; i++) {
            var worksheet = file.Sheets[listColumnNames[i]];
            for (let key in worksheet) {
                let regEx = new RegExp("^\(\\w\)\(1\){1}$");

                if (regEx.test(key) == true) {
                    columnNames.push(worksheet[key].v);
                }

            }
        }
        let extraColumns = [];
        for (let i = 0; i < columnNames.length; i++) {
            if (columnNames[i] !== 'numero') {
                extraColumns.push(noSpecialCharacter(columnNames[i]).toLowerCase());
            }
        }
        setMessageVariable(extraColumns);
    }

    const formatPhone = (phone) => {
        try {
            if (!phone) throw new Error('telefone não identificado');
            // remover caracteres especiais
            phone = phone.toString().replace(/\D/g, '');
            let add = false;
            // verificar quantidade minima e máxima de caracteres
            if (phone.length < 10 || phone.length > 13) return '';
            // verificar se não começar com o 55, adicionar
            if (phone.substring(0, 2) != '55') add = true;
            else if (add == false && (phone.length == 10 || phone.length == 11)) add = true;

            return add == true ? '55' + phone : phone;
        } catch (err) {
            console.error(phone);
            console.error(err);
            return '';
        }
    }

    const isContactValid = (contact, validation_status) => {
        return (contact.nome == '' || !('nome' in contact) || !('numero' in contact) || validation_status == false) ? false : true;
    }

    const formatContact = (contact) => {
        let template = {};
        for (const property in contact) {
            let propertyParsed = noSpecialCharacter(property).toLowerCase();
            if (propertyParsed == 'nome') template['name'] = contact[property];
            else if (propertyParsed == 'numero') template['number'] = contact[property];
            else template[propertyParsed] = contact[property];
        }
        return template;
    }

    const updateProgress = (percentage, total) => {
        const progressBar = document.querySelector("#progressBar");
        const progress = ((percentage * 100) / total).toFixed(0);
        progressBar.style.width = progress + '%';
        progressBar.innerHTML = progress + '%';
    }

	// calcular estimativa de tempo
	const calcTimeEstimate = (qty) => {
		/*
			média de tempo para envio da campanha
			quantidade total x média em segundos do tempo de espera entre cada msg
		*/
		let estimate = qty * 52;
		
		// dividir total de contatos para adicionar estimativa de pausa;
		let separator = 10;
		let pauses = qty / separator;
		if (pauses > 1) {
			pauses = Math.floor(pauses);
			/*
				média de tempo para envio da campanha
				pausas x média em segundos do tempo de espera entre cada onda de mensagens
			*/
			estimate += (pauses * 90);
		}

		if (estimate >= 3600) setTimeEstimate(`${Math.ceil((estimate / 60) / 60)} horas`);
		else if (estimate >= 60) setTimeEstimate(`${Math.ceil(estimate / 60)} minutos`);
		else setTimeEstimate(`${estimate} segundos`);
	}

    const processSheetData = async (file) => {
        let data = [];
        let error = [];
        let percentage = 0;

        const sheets = file.SheetNames;
        for (let i = 0; i < sheets.length; i++) {
            const temp = XLSX.utils.sheet_to_json(file.Sheets[file.SheetNames[i]]);
            let numberArray = [];
            for (let ct = 0; ct < temp.length; ct++) {
                let res = temp[ct];
                percentage++

                res.numero = formatPhone(res.numero);

                if (numberArray.includes(res.numero)) {
                    // error.push(res);
                } else {
                    numberArray.push(res.numero)

                    let validation_status = await PhoneValidator(res.numero);

                    if (!isContactValid(res, validation_status)) {
                        error.push(res);
                    } else {
                        const obj = formatContact(res);
                        data.push(obj);
                    }
                }
                updateProgress(percentage, temp.length);
            }
            // média de tempo para envio da campanha
            calcTimeEstimate(numberArray.length);
        }
        return { data, error };
    }

    const getCalcDays = {
        FormatData(data) {
            const date = new Date(new Date(data).getTime() + 15 * 60000);
            const days = padTo2Digits(date.getDate());
            const month = padTo2Digits(date.getMonth() + 1);
            const year = date.getFullYear();
            const hoursAndMinutes = `${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}`;
            const dataFormated = new Date().toLocaleString("pt-BR", {
                timeZone: "America/Sao_Paulo"
            });

            return {
                dataBr: `${days}-${month}-${year}`,
                dataInput: `${year}-${month}-${days}`,
                dataAndTime: dataFormated.substring(0, 16),
                dataBefore15Minutes: `${year}-${month}-${days} ${hoursAndMinutes}`
            };
        },
        getDataFormated(day) {
            const week = new Date();
            week.setDate(week.getDate() + day);
            return this.FormatData(week);
        },
        calcDays(contatos) {
            const dia = 86400;
            const calculo = dia / 30;
            const totalContatos = contatos;
            const result = Math.ceil(totalContatos / calculo);
            return {
                diasTotais: result,
                dataPrevista: this.getDataFormated(result).dataBr
            };
        }
    };

    const getTimeMinToSend = (total) => {
        // Convertendo o total em segundos.
        const totalSeconds = total * 30;

        // Função auxiliar para converter segundos em formato HH:MM:SS.
        function secondsToHoursFormat(seconds) {
            const hours = padTo2Digits(Math.floor(seconds / 3600));
            const minutes = padTo2Digits(Math.floor((seconds % 3600) / 60));
            const remainingSeconds = padTo2Digits(seconds % 60);

            return `${hours}:${minutes}:${remainingSeconds}`;
        }

        return secondsToHoursFormat(totalSeconds);
    }

    const getErrosExcel = () => {
        var data = contactsError
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Responses')
        XLSX.writeFile(wb, 'contatos.xlsx')
    }

    const initUIForProcessing = (toastFlag = true) => {
        const resultRelatory = document.querySelector("#resultRelatory");
        const progressRelatory = document.querySelector("#progressRelatory");
        const infoContactsSend = document.querySelector("#infoContactsSend");

        progressRelatory.style.display = '';
        resultRelatory.style.display = 'flex';
        resultRelatory.style.opacity = '0';

        if (toastFlag == true) toast.info("Aguarde a validação dos contatos para prosseguir.");
    }

    const updateUIPostProcessing = (data, error) => {

        const successContacts = document.querySelector("#successContacts");
        const errorContacts = document.querySelector("#errorContacts");
        const contactsResult = document.querySelector("#contactsResult");
        const contactsEnd = document.querySelector("#contactsEnd");
        const resultRelatory = document.querySelector("#resultRelatory");

        contactsResult.innerHTML = `Enviados: ${data.length + error.length}`;
        errorContacts.innerHTML = `Erros: ${error.length}`;
        successContacts.innerHTML = `Válidos: ${data.length}`;
        contactsEnd.innerHTML = `Estimativa: ${getTimeMinToSend(data.length)}`;
        resultRelatory.style.opacity = '1';
    }

    const csvUpload = async (e) => {
        const extFile = e.target.files[0].name.split('.').pop();

        if (!VALID_EXTENSIONS.includes(extFile)) {
            toast.error(`Selecione um arquivo correto para os contatos da campanha.`);
            return;
        }

        initUIForProcessing();

        const reader = new FileReader();
        reader.onload = async (e) => {
            const arquivo = e.target.result;
            const file = XLSX.readFile(arquivo);
            let { data, error } = await processSheetData(file);

            updateUIPostProcessing(data, error);
            setContacts(data);
            setContactsError(error);
            getColumnsFromFile(file);
        }
        reader.readAsArrayBuffer(e.target.files[0]);
    }

    // Função auxiliar para exibir imagens
    const displayImage = (element, source, title) => {
        if (!element) return;

        element.style.display = "";
        element.src = source;
        element.title = title;
    }

    //Transformar os arquivos em BASE64
    const csvArhive = () => {
        const MAX_SIZE = 8388608;  // 8MB em bytes
        let b64 = "";

        // Seletores de elementos DOM
        const imgUploadedUser = document.querySelector('#imgUploaded');
        const audioPlay = document.querySelector('#playerAudioUser');
        const playlist = document.querySelector('#playlist');
        const input = document.querySelector('#uploadArquivo');

        // Resetar exibições
        playlist.style.display = "none";
        imgUploadedUser.style.display = "none";

        const file = input.files[0];
        const reader = new FileReader();
        const fileSize = file.size;

        // Verificar tamanho do arquivo
        if (fileSize > MAX_SIZE) {
            input.value = "";
            return toast.error('O Arquivo não pode ter mais de 8MB');
        }

        const nameFile = file.name.replace(/\.[^/.]+$/, ""); // Remove extensão para obter nome do arquivo
        const extFile = file.name.split('.').pop();          // Obter extensão do arquivo

        reader.onloadend = () => {
            b64 = reader.result;
            const typeArchive = b64.split('/')[0].split(':')[1];

            if (typeArchive === 'image') {
                displayImage(imgUploadedUser, b64, "Imagem enviada pelo usuário");
            } else if (typeArchive === 'audio') {
                audioPlay.src = b64;
                displayImage(imgUploadedUser, musicSvg, "Áudio enviado pelo usuário");
                playlist.style.display = "";
            } else {
                switch (extFile) {
                    case 'pdf':
                        displayImage(imgUploadedUser, pdfSvg, "PDF enviado pelo usuário");
                        break;
                    case 'xlsx':
                        displayImage(imgUploadedUser, xlsxSvg, "XLSX enviado pelo usuário");
                        break;
                    case 'csv':
                        displayImage(imgUploadedUser, csvSvg, "CSV enviado pelo usuário");
                        break;
                    default:
                        displayImage(imgUploadedUser, upload, "Arquivo enviado pelo usuário");
                        break;
                }
            }

            if (b64.length < 15) {
                toast.error('Arquivo inválido para upload.');
                return;
            }

            setMedia64archive(`name(${nameFile})${b64}`);
        };

        reader.readAsDataURL(file);
        setBlockedMessage('enable');
    }

    // Inicia a gravação
    const startRecording = () => {
        toggleElementDisplay('#stop', "flex");
        toggleElementDisplay('#playlist', "none");
        toggleElementDisplay('#imgUploaded', "none");

        setBlockedMessage('disable');

        recorder.start()
            .then(() => { /* No operation */ })
            .catch(e => console.error(e));
    };

    const stopRecording = () => {
        const imgUploadedUser = document.querySelector('#imgUploaded');
        const audioPlay = document.querySelector('#playerAudioUser');

        toggleElementDisplay('#stop', "none");

        recorder.stop().getMp3()
            .then(([buffer, blob]) => {
                const file = new File(buffer, 'music.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                });
                const blobFile = URL.createObjectURL(file);
                const player = new Audio(blobFile);

                toggleElementDisplay('#playlist', "");

                player.controls = true;

                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    audioPlay.src = base64data;
                    if (base64data.length < 15) {
                        toast.error(`Ocorreu um erro ao salvar o áudio, tente novamente.`);
                        return;
                    }
                    setMedia64archive(`name(userRecAudio)${base64data}`);
                }

                toggleElementDisplay('#imgUploaded', "");
                imgUploadedUser.src = headsetSvg;
                imgUploadedUser.title = "Áudio gravado pelo usuário";
            })
            .catch((e) => {
                console.error(e);
            });
    }

    const playAudio = () => {
        const audio = document.querySelector('audio');
        audio.play();
        toggleElementDisplay('#playerStart', 'none');
        toggleElementDisplay('#playerEnd', '');
        audio.onended = () => {
            toggleElementDisplay('#playerStart', '');
            toggleElementDisplay('#playerEnd', 'none');
        }
    }

    // Função auxiliar para manipulação de DOM
    const toggleElementDisplay = (elementId, displayValue) => {
        const element = document.querySelector(elementId);
        if (element) element.style.display = displayValue;
    };

    return (
        <Box>
            <Dialog
                open={open == true ? true : false}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                scroll="paper"
                disableEscapeKeyDown={true}
                className={classes.dialog}
            >
                <DialogTitle style={{ marginTop: '10px' }}>
                    {`Editar Campanha`}
                </DialogTitle>
                <Formik
                    initialValues={campaign}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        handleSave(values);
                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form style={{ padding: "10px" }}>
                            <DialogContent
                                style={{ overflowY: "hidden" }}
                                dividers
                            >
                                <Grid container spacing={5}>
                                    <Grid item sm={12} mb={2}>
                                        <InputStyled
                                            as={Switch}
                                            label="Evitar feriados"
                                            name="holiday"
                                            color="primary"
                                            checked={values.holiday}
                                            primaryClass={classes.switch}
                                            style={{ margin: '0px', position: 'absolute', bottom: '2px', left: 0 }}
                                            onChange={(e) => setCampaign({ ...campaign, holiday: e.target.checked })}
                                            disabled={campaign.status == 'finished'}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={6} mb={2}>
                                        <DatePickerBootstrap
                                            name="start_date"
                                            id="start_date"
                                            className="form-control"
                                            primaryClass="form-group"
                                            label="Data de inicio"
                                            secondClass="input-group mb-2"
                                            minDate={formatInitialDate()}
                                            value={values.start_date}
                                            onClickElement={e => openDatePicker('start_date')}
                                            onChangeDate={e => checkDate(e)}
                                            required={false}
                                            disabled={campaignStarted || campaign.status == 'finished'}
                                        />
                                    </Grid>
                                    <Grid item sm={6} mb={2}>
                                        <GroupedInputStyled
                                            primaryClass="form-group"
                                            inputsProps={[
                                                {
                                                    name: "start_hours",
                                                    id: 'start_hours',
                                                    value: values.start_hours,
                                                    placeholder: "00",
                                                    onChange: (e) => format(e),
                                                    onBlur: (e) => minFormat(e)
                                                },
                                                {
                                                    name: "start_minutes",
                                                    id: 'start_minutes',
                                                    value: values.start_minutes,
                                                    placeholder: "00",
                                                    onChange: (e) => format(e)
                                                }
                                            ]}
                                            separator=":"
                                            className="form-control"
                                            label="Horário de início:"
                                            style={{ height: '35px', width: '25px' }}
                                            disabled={campaignStarted || campaign.status == 'finished'}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={6} mb={2}>
                                        <DatePickerBootstrap
                                            name="end_date"
                                            id="end_date"
                                            className="form-control"
                                            primaryClass="form-group"
                                            label="Data de finalização"
                                            secondClass="input-group mb-2"
                                            minDate={formatInitialDate()}
                                            value={values.end_date}
                                            onClickElement={e => openDatePicker('end_date')}
                                            onChangeDate={e => checkDate(e)}
                                            required={false}
                                            disabled={campaign.status == 'finished'}
                                        />
                                    </Grid>
                                    <Grid item sm={6} mb={2}>
                                        <GroupedInputStyled
                                            primaryClass="form-group"
                                            inputsProps={[
                                                {
                                                    name: "end_hours",
                                                    id: 'end_hours',
                                                    value: values.end_hours,
                                                    placeholder: "00",
                                                    onChange: (e) => format(e),
                                                    onBlur: (e) => minFormat(e)
                                                },
                                                {
                                                    name: "end_minutes",
                                                    id: 'end_minutes',
                                                    value: values.end_minutes,
                                                    placeholder: "00",
                                                    onChange: (e) => format(e)
                                                }
                                            ]}
                                            separator=":"
                                            className="form-control"
                                            label="Horário de finalização:"
                                            style={{ height: '35px', width: '25px' }}
                                            disabled={campaign.status == 'finished'}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} mb={2}>
                                        <Box style={{
                                            width: 'min-content',
                                            margin: '0 auto',
                                            display: 'flex'
                                        }}>
                                            <FormGroup style={{ display: 'contents' }}>
                                                {weekDays.map((day, i) => (
                                                    <FormControlLabel
                                                        key={day.name}
                                                        control={
                                                            <Checkbox
                                                                id={day.name}
                                                                name={day.name}
                                                                onChange={() => handleSelect(day.name)}
                                                                disabled={weekDayBlocked === day.name || campaign.status == 'finished'}
                                                                value={day.name}
                                                                checked={(Array.isArray(campaign.weekDays) && campaign.weekDays.findIndex((selectedDay) => selectedDay === day.name) != -1 || checkIsCkeched(day.name, weekDayBlocked))}
                                                            />
                                                        }
                                                        label={day.label}
                                                        style={{ margin: '0px' }}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} mb={2}>
                                        <FormControl>
                                            <FormLabel>Selecione a origem da base de contatos:</FormLabel>
                                            <RadioGroup
                                                row
                                                name="selectOrigin"
                                            >
                                                <FormControlLabel control={<Radio color="primary" checked={contactsFromTags} onClick={() => setContactsFromTags(!contactsFromTags)} />} label="Etiquetas" disabled={campaign.status == 'finished'} />
                                                <FormControlLabel
                                                    control={<Radio color="primary" checked={!contactsFromTags} onClick={() => setContactsFromTags(!contactsFromTags)} />}
                                                    label={
                                                        <>
                                                            Importação de planilha
                                                            <Tooltip
                                                                title={
                                                                    <>
                                                                        <Typography gutterBottom color="inherit">Para adicionar contatos na campanha via importação de planilha, siga os passos abaixo:</Typography>
                                                                        <Typography gutterBottom color="inherit">1º - Clique no botão "Download do Modelo";</Typography>
                                                                        <Typography gutterBottom color="inherit">2º - Na planilha baixada, mantenha os nomes das colunas já existentes (essas informações não podem ser alteradas);</Typography>
                                                                        <Typography gutterBottom color="inherit">3º - Adicione as informações dos contatos, formatando os números dos contatos como DDI+DDD+NUMERO;</Typography>
                                                                        <Typography gutterBottom color="inherit">4º - Caso queira adicionar mais variáveis na mensagem da campanha, adicione novas colunas nomeando as varáveis na primeira linha das novas colunas e abaixo as informações das variáveis;</Typography>
                                                                        <Typography gutterBottom color="inherit">5º Importe a planilha ajustada para a Plataforma através do botão "Enviar Contatos".</Typography>
                                                                    </>
                                                                }
                                                                placement="bottom"
                                                                style={{ marginLeft: "10px" }}
                                                            >
                                                                <HelpIcon />
                                                            </Tooltip>
                                                        </>
                                                    }
                                                    disabled={campaign.status == 'finished'}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* etiquetas */}
                                <Grid container spacing={5} hidden={!contactsFromTags}>
                                    <Grid item sm={12} mb={2} style={{ position: "relative" }}>
                                        {(countContacts && countContacts >= 0) &&
                                            <Box style={{
                                                float: 'right'
                                            }}>
                                                Qtd de contatos: {countContacts}
                                            </Box>
                                        }
                                        <MultiSelectChip
                                            disabled={contacts.length >= 1 || campaignStarted || campaign.status == 'finished'}
                                            selectedTagsIds={selectedTagsIds}
                                            onChange={(values) => {
                                                setSelectedTagsIds(values);
                                                setMessageVariable(['nome']);
                                            }}
                                            onClose={() => countContactsByTags()}
                                            formGroup={false}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5} hidden={!contactsFromTags}>
                                    <Grid item sm={12} mb={2}>
                                        <InputStyled
                                            as={Switch}
                                            label={
                                                <>
                                                    Adicionar variáveis na mensagem da campanha
                                                    <Tooltip
                                                        title={
                                                            <>
                                                                <Typography gutterBottom color="inherit">Para adicionar mais variáveis na sua mensagem, siga os passos abaixo:</Typography>
                                                                <Typography gutterBottom color="inherit">1º - Clique no botão “Download do Modelo”;</Typography>
                                                                <Typography gutterBottom color="inherit">2º - Na planilha baixada, mantenha os nomes das colunas e informações já existentes (essas informações não podem ser alteradas);</Typography>
                                                                <Typography gutterBottom color="inherit">3º - Adicione novas colunas nomeando as varáveis na primeira linha das novas colunas e abaixo as informações das variáveis;</Typography>
                                                                <Typography gutterBottom color="inherit">4º - Após concluir, salve o arquivo com as alterações realizadas;</Typography>
                                                                <Typography gutterBottom color="inherit">5º - Importe a planilha ajustada para a Plataforma através do botão “Enviar Arquivo”.</Typography>
                                                            </>

                                                        }
                                                        placement="bottom"
                                                        style={{ marginLeft: "10px" }}
                                                    >
                                                        <HelpIcon />
                                                    </Tooltip>
                                                </>
                                            }
                                            name="holiday"
                                            color="primary"
                                            checked={addVariables}
                                            onChange={() => setAddVariables(!addVariables)}
                                            disabled={campaignStarted || campaign.status == 'finished'}

                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5} hidden={contactsFromTags && !addVariables}>
                                    <Grid item sm={12} mb={2}>
                                        <Button
                                            style={{ width: "100%" }}
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            href={excelContacts}
                                            download="Contatos.xlsx"
                                            target='_blank'
                                            onClick={(e) => {
                                                if (selectedTagsIds.length > 0) {
                                                    e.preventDefault();

                                                    const fetchSpreadsheet = async () => {
                                                        try {
                                                            setLoading(true);
                                                            const { data } = await api.post('/campaign/getModelByTags', { tagsId: selectedTagsIds });

                                                            if (data.contacts) {
                                                                // Cria uma nova planilha
                                                                const worksheet = XLSX.utils.json_to_sheet(data.contacts);
                                                                const workbook = XLSX.utils.book_new();
                                                                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                                                                // Converte o workbook para binário
                                                                const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

                                                                // Define o nome do arquivo
                                                                const fileName = "Contatos.xlsx";

                                                                // Função auxiliar para converter string em buffer
                                                                const s2ab = (s) => {
                                                                    const buf = new ArrayBuffer(s.length);
                                                                    const view = new Uint8Array(buf);
                                                                    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                                                                    return buf;
                                                                };

                                                                // Cria um blob a partir do buffer
                                                                const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

                                                                const url = window.URL.createObjectURL(blob);
                                                                const a = document.createElement('a');
                                                                a.style.display = 'none';
                                                                a.href = url;
                                                                a.download = fileName;
                                                                document.body.appendChild(a);
                                                                a.click();
                                                                window.URL.revokeObjectURL(url);

                                                                // Remove o link e libera o objeto URL
                                                                window.URL.revokeObjectURL(url);
                                                                document.body.removeChild(a);

                                                            }

                                                        } catch (err) {
                                                            console.error({err});
                                                            toastError(err);
                                                        } finally {
                                                            setLoading(false);
                                                        }
                                                    }

                                                    fetchSpreadsheet();
                                                }
                                            }}
                                            disabled={loading || campaign.status == 'finished'}
                                        >
                                            Download do Modelo
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                />
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5} hidden={contactsFromTags && !addVariables}>
                                    <Grid item sm={12} mb={2}>
                                        <FileUploader
                                            disabled={loading || campaignStarted || campaign.status == 'finished'}
                                            disabledInput={loading || campaignStarted || campaign.status == 'finished'}
                                            csvUpload={csvUpload}
                                            contactsError={contactsError}
                                            getErrosExcel={getErrosExcel}
                                            formGroup={false}
                                            buttonLabel={contactsFromTags ? "Enviar Arquivo" : "Enviar Contatos"}
                                        />
                                    </Grid>
                                </Grid>

                                {
                                    timeEstimate != null &&
                                    <Grid container spacing={5}>
                                        <Grid item sm={12} mb={2}>
                                            <Alert severity="warning" style={{ width: '100%', marginBottom: '20px' }}>
                                                Tempo aproximado de {timeEstimate} para efetuar o envio da campanha.
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                }

                                <Grid container spacing={5}>
                                    <Grid item sm={6} mb={2}>
                                        <Box hidden={loading}>
                                            <p style={{ margin: '0px 0px -5px' }}>
                                                Enviar arquivo
                                            </p>
                                            <input
                                                disabled={contacts.length <= 0 && selectedTagsIds.length == 0 || campaign.status == 'finished'}
                                                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.mp4,.mkv,.mov,.avi,.jpg,.jpeg,.png,.gif"
                                                style={{ display: 'none' }}
                                                id="uploadArquivo"
                                                type="file"
                                                name="arquivo"
                                                onChange={e => csvArhive(e)}
                                            />
                                            <label htmlFor="uploadArquivo" style={{ marginTop: '6px', width: '100%' }}>
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    className={classes.button}
                                                    color="primary"
                                                    style={{ padding: '13px 23px 13px 30px', width: '100%' }}
                                                    disabled={contacts.length <= 0 && selectedTagsIds.length == 0 || campaign.status == 'finished'}
                                                >
                                                    <p style={{ margin: '0px 5px -2px 0px' }}>ARQUIVO</p>
                                                    <BackupIcon />
                                                </Button>
                                            </label>
                                        </Box>
                                    </Grid>

                                    <Grid item sm={6} mb={2} style={{ position: 'relative' }}>
                                        <Box>
                                            <p style={{ margin: '0px 0px -5px' }}>
                                                Gravar áudio
                                            </p>
                                            <Box>
                                                <Button
                                                    onClick={() => {
                                                        values.message = '';
                                                        startRecording()
                                                    }}
                                                    title="Gravar áudio"
                                                    id="start"
                                                    className="btn btn-sm btn-success"
                                                    style={{
                                                        height: '44px',
                                                        borderRadius: '5px',
                                                        marginTop: '6px',
                                                        padding: '5px 30px 5px 30px',
                                                        display: 'flex',
                                                        width: '100%'
                                                    }}
                                                    disabled={contacts.length <= 0 && selectedTagsIds.length == 0 || campaign.status == 'finished'}
                                                >
                                                    <SettingsVoiceIcon />
                                                    <p style={{ marginTop: '15px' }}> Gravar</p>
                                                </Button>
                                                <Button onClick={() => { stopRecording() }} title="Parar Gravacao" id="stop" className="btn btn-sm btn-danger" style={{
                                                    height: "47px",
                                                    borderRadius: "5px",
                                                    marginTop: "6px",
                                                    padding: "14px !important",
                                                    display: "none",
                                                    width: "calc(100% - 40px)",
                                                    position: "absolute",
                                                    bottom: "25px"
                                                }}>
                                                    <StopIcon color="black" />
                                                    <p id="timerRec" style={{ marginTop: '15px' }}>Gravando...</p>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} mb={2}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <>
                                                <img id="imgUploaded" src="" style={{ height: '45px', borderRadius: '5px', display: 'none' }} />
                                                <p id="playlist" style={{ display: 'none' }}>
                                                    <audio id="playerAudioUser" controls="controls" autobuffer="autobuffer"></audio>
                                                </p>
                                                <a onClick={() => { playAudio() }} title="Reproduzir Audio" id="play" className={classes.customButton} style={{ height: '35px', borderRadius: '30px', width: '35px', background: '#b1b1b1', margin: '0.57rem 10px 0px 10px', display: 'none' }}>
                                                    <Box id="play_pause">
                                                        <svg id="playerStart" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16" style={{ margin: "0px 0px 0px -8px" }}>
                                                            <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                                                        </svg>
                                                        <svg id="playerEnd" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pause-fill" viewBox="0 0 16 16" style={{ margin: "0px 0px 0px -8px", display: 'none' }}>
                                                            <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
                                                        </svg>
                                                    </Box>
                                                </a>
                                            </>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} mb={2}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <InputStyled
                                                as={Switch}
                                                label={
                                                    <>
                                                        Enviar mensagem com link para se descadastrar do recebimento de campanhas?
                                                        <Tooltip
                                                            title={
                                                                <>
                                                                    <Typography gutterBottom color="inherit">A utilização deste recurso diminui a probabilidade de banimento do número da empresa, uma vez que o destinatário da mensagem terá a opção de se descadastrar dos recebimentos de campanhas realizadas através do número de disparo configurado na campanha, evitando desta forma que o destinatário clique na opção de "Spam" do WhatsApp.</Typography>
                                                                    <Typography gutterBottom color="inherit">Ao ativar esta função, o bloqueio aplica-se somente para envio de mensagens de campanhas do número WhatsApp da empresa que foi configurado na campanha (esta regra é por número WhatsApp da empresa), podendo interagir normalmente via atendimento.</Typography>
                                                                </>
                                                            }
                                                            placement="bottom"
                                                            style={{ marginLeft: "10px" }}
                                                        >
                                                            <HelpIcon />
                                                        </Tooltip>
                                                    </>
                                                }
                                                name="sendBlackListMessage"
                                                color="primary"
                                                checked={values.sendBlackListMessage}
                                                primaryClass="form-group"
                                                disabled={contacts.length <= 0 && selectedTagsIds.length == 0 || campaign.status == 'finished'}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5} style={{ display: values?.sendBlackListMessage == true ? 'flex' : 'none' }}>
                                    <Grid item sm={12} mb={2}>
                                        <Box
                                            style={{
                                                justifyContent: 'space-between',
                                                marginBottom: 0
                                            }}
                                        >
                                            <Box style={{ display: 'flex', width: '100%', paddingBottom: 0 }}>

                                                <Field
                                                    disabled={contacts.length <= 0 && selectedTagsIds.length == 0 || campaign.status == 'finished'}
                                                    id="inputBlackListMessage"
                                                    as="textarea"
                                                    style={{
                                                        borderColor: '#c4c4c4',
                                                        borderRadius: '3px',
                                                        float: 'left'
                                                    }}
                                                    inputRef={greetingRef}
                                                    rows='4'
                                                    className='form-control'
                                                    type="message"
                                                    placeholder="Mensagem..."
                                                    name="blackListMessage"
                                                    maxLength="220"
                                                />
                                                <Box
                                                    style={{
                                                        float: "right",
                                                        display: (contacts.length > 0 || selectedTagsIds.length > 0) && values?.sendBlackListMessage == true ? '' : 'none',
                                                    }}
                                                >
                                                    <IconButton
                                                        aria-label="emojiPicker"
                                                        component="span"
                                                        onClick={(e) => setShowBlackListEmoji((prevState) => !prevState)}
                                                    >
                                                        <MoodIcon className={classes.sendMessageIcons} />
                                                    </IconButton>

                                                    <EmojiDataPicker
                                                        onEmojiSelect={
                                                            emoji => {
                                                                setCampaign(() => {
                                                                    // verificar se já chegou no limite do textbox
                                                                    if (!isNaN(values.blackListMessage.length) && !isNaN(document.querySelector('#inputBlackListMessage').maxLength)) {
                                                                        if (values.blackListMessage.length >= (document.querySelector('#inputBlackListMessage').maxLength - 2)) return values;
                                                                    }
                                                                    return { ...values, blackListMessage: `${values?.blackListMessage}${emoji}` };
                                                                })
                                                            }}
                                                        disabled={false}
                                                    />

                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5} style={{ display: values?.sendBlackListMessage == true ? 'flex' : 'none' }}>
                                    <Grid item sm={12} mb={2}>
                                        <Box
                                            style={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography style={{ display: 'inline-block', width: '100%' }}>
                                                {`${values.blackListMessage.length}/220`}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} mb={2}>
                                        <Box>
                                            {
                                                values.sendBlackListMessage != true &&
                                                <Alert severity="warning" style={{ width: '100%', marginBottom: '20px' }}>
                                                    Não utilizar a opção de envio do link de descadastramento de campanhas, pode aumentar a possibilidade de banimento de sua conexão/número WhatsApp.
                                                </Alert>
                                            }
                                        </Box>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} mb={2}>
                                        <Box className={`${blockedMessage == 'disable' ? 'blockedMessage' : ''}`}>
                                            <label>
                                                Mensagem de disparo da campanha
                                                <span className="text-danger ml-2">*</span>
                                            </label>
                                            {messageVariable.length > 0 &&
                                                <Box style={{ display: 'block' }}>
                                                    {messageVariable.map(msg => (
                                                        <Box style={{ padding: '5px', display: 'inline-block' }}>
                                                            <Chip
                                                                color="primary"
                                                                label={msg}
                                                                onClick={e => {
                                                                    if (campaign.status == 'finished') return;
                                                                    setCampaign(() => {
                                                                        return { ...values, message: `${values?.message}{{${msg}}}` };
                                                                    })
                                                                    // voltar cursor para campo de texto
                                                                    let text_tmp = document.querySelector('textarea[name="message"]');
                                                                    if (text_tmp.setSelectionRange) {
                                                                        text_tmp.focus();
                                                                        text_tmp.setSelectionRange(-1, -1)
                                                                    } else if (text_tmp.createTextRange) {
                                                                        let range = text_tmp.createTextRange();
                                                                        range.collapse(true);
                                                                        range.moveEnd('character', -1);
                                                                        range.moveStart('character', -1);
                                                                        range.select();
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            }
                                            <Box>
                                                <Box style={{ display: 'flex' }}>

                                                    <Field
                                                        id="inputMessageCampaign"
                                                        as="textarea"
                                                        style={{
                                                            borderColor: '#c4c4c4',
                                                            borderRadius: '3px'
                                                        }}
                                                        inputRef={greetingRef}
                                                        rows='4'
                                                        className='form-control'
                                                        type="message"
                                                        placeholder="Mensagem..."
                                                        name="message"
                                                        disabled={(contacts.length == 0 && selectedTagsIds.length == 0 && values?.sendBlackListMessage == true ? true : false) || campaign.status == 'finished'}
                                                        maxLength={values?.sendBlackListMessage == true ? 800 : 1020}
                                                        onBlur={() => {
                                                            // certificar que todos os links tenha https
                                                            if (!values.message) return;
                                                            let array = values.message.split(" ");
                                                            let response = '';
                                                            for (let i = 0; i < array.length; i++) {
                                                                if (CheckUrl(array[i]) == true && array[i].indexOf('https://') == -1) {
                                                                    let tmp = array[i].split('://');
                                                                    tmp = tmp[tmp.length - 1];
                                                                    response += `https://${tmp}`;
                                                                }
                                                                else { response += array[i] }

                                                                // se não for a ultima posição adicionar um espaço
                                                                if (i < (array.length - 1)) response += ' ';
                                                            };

                                                            setCampaign({ ...values, message: response });
                                                        }}
                                                    />
                                                    <Box
                                                        style={{
                                                            display: (contacts.length > 0 || selectedTagsIds.length > 0) && values?.sendBlackListMessage == true ? '' : 'none',
                                                        }}
                                                    >

                                                        <EmojiDataPicker
                                                            onEmojiSelect={
                                                                emoji => {
                                                                    setCampaign(() => {
                                                                        // verificar se já chegou no limite do textbox
                                                                        if (!isNaN(values.message.length) && !isNaN(document.querySelector('#inputMessageCampaign').maxLength)) {
                                                                            if (values.message.length >= (document.querySelector('#inputMessageCampaign').maxLength - 2)) return values;
                                                                        }
                                                                        return { ...values, message: `${values?.message}${emoji}` };
                                                                    })
                                                                }}
                                                            disabled={false}
                                                        />


                                                    </Box>
                                                </Box>
                                                <Typography>
                                                    {values?.sendBlackListMessage ? `${values.message.length}/800` : `${values.message.length}/1020`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </DialogContent>
                            <DialogActions
                                style={{
                                    position: "sticky",
                                    bottom: "0",
                                    right: "0",
                                    zIndex: "10",
                                    background: "#fff"
                                }}
                            >
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={loading}
                                    variant="outlined"
                                >
                                    {i18n.t("openingHourModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading || campaign.status == 'finished'}
                                    variant="contained"
                                >
                                    {`${i18n.t("openingHourModal.buttons.okEdit")}`}
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Box>
    );
}

export default ChatbotCampaignModal;