const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      dashboard: {
        title: "Bem vindo",
        updatedAt: "atualizado em: ",
        tickets: "Atendimentos",
        chart: {
          title: "Total: ",
          legends: {
            open: "Atendendo",
            pending: "Aguardando",
            closed: "Finalizados"
          }
        },
        filters: {
          default: "selecione um filtro",
          updatedToday: "atualizados hoje",
          unanswered: "não lidos"
        },
        cards: {
          inAttendance: "ATENDENDO",
          waiting: "ATENDIMENTOS AGUARDANDO RETORNO",
          activeAttendants: "Atendentes On-line",
          finalized: "ATENDIMENTOS FINALIZADOS (DO DIA)",
          ticketWithoutAttendants: "ATENDIMENTOS C/ ATENDENTE OFF-LINE",
          totalReceivedMessages: "MENSAGENS RECEBIDAS (DO DIA)",
          totalSentMessages: "MENSAGENS ENVIADAS (DO DIA)",
          averageServiceTime: "T.M. DE ATENDIMENTO",
          chatbotTickets: "ATENDIMENTOS NO FLUXO CHATBOT",
          status: "Status (Atual)",
          activeTickets: "CAMPANHAS ATIVAS",
          passiveTickets: "ATENDIMENTOS DO DIA",
          unread: "MENSAGENS NÃO LIDAS",
        },
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        form: {
          name: 'nome',
          message: 'Mensagem de saudação',
          queue: 'Selecionar filas vinculadas',
          getContactOnHold: 'Puxar contato que está em espera ao iniciar um atendimento',
          msgNotification: 'Receber notificação de mensagem no celular ?'
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR CODE novamente.",
        },
        buttons: {
          add: "Adicionar Conexão",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
          loginFacebook: "Login com o FaceBook",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR CODE",
          },
          qrcode: {
            title: "Esperando leitura do QR CODE",
            content:
              "Clique no botão 'QR CODE' e leia o QR CODE com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR CODE",
          },
        },
        table: {
          channel: "Canal",
          name: "Nome",
          status: "Status",
          limit: "Limite",
          numberStatus: "Status do número",
          qualityRating: "Classificação de Qualidade",
          lastUpdate: "Última atualização",
          lastDisconnection: "Última desconexão",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
          option: {
            title: "Tipo de conexão",
            receptive: "Atendimento",
            massive: "Mensagens Massivas"
          },
          number: "Número"
        },
        whatsapp: {
          template: {
            title: "Modelos de Whatsapp",
            table: {
              id: "Id",
              name: "Nome",
              status: "Status",
              qualityRating: "Classificação de Qualidade",
              lastUpdated: "Última Atualização",
              actions: "Opções",
              notFound: "Nenhum modelo foi encontrado",
              filters: {
                quantity: "Quantidade"
              }
            },
            modal: {
              storeTitle: "Criar Modelo",
              editTitle: "Editar Modelo",
              name: "Nome",
              language: "Idioma",
              selectLanaguage: "Selecione um Idioma",
              connection: "Conexão",
              selectConnection: "Selecione uma Conexão",
              email: "Email",
              emailPlaceholder: "Pressione enter para adicionar um ou mais e-mails.",
              emailInfo: "Digite o(s) e-mail(s) para ser notificado quando seu modelo for revisado pela equipe do Whatsapp. Após digitar cada endereço de e-mail, pressione a tecla \"Enter\" para confirmar.",
              category: "Categoria",
              selectCategory: "Selecione uma Categoria",
              categoryOptions: {
                authentication: "Autenticação",
                marketing: "Marketing",
                utility: "Utilidade"
              },
              messageType: {
                title: "Tipo de Mensagem",
                subtitle: "Você pode escolher entre os dois tipos de mensagens abaixo. O limite máximo de caracteres pode variar dependendo do tipo escolhido.",
                simple: {
                  title: "Mensagem simples",
                  body: "Olá, sua mensagem aqui.",
                  max: "A mensagem pode conter até 1024 caracteres."
                },
                customMessageSelected: {
                  title: "Mensagem com cabeçalho, mídia, rodapé e botões",
                  header: "Cabeçalho da mensagem",
                  body: "Olá, sua mensagem aqui.",
                  footer: "Rodapé da mensagem",
                  max: "A mensagem pode conter até 1024 caracteres.",
                  buttonLink: "Link do botão"
                },
                otpMessageSelected: {
                  title: "Mensagem com botão OTP",
                  body: "797011 é seu código de verificação. Para sua segurança, não compartilhe este código.",
                  expires: "Este código expira em 5 minutos.",
                  copy: "Copiar código"
                },
              },
              authentication: "O uso de URLs, mídias e emojis no conteúdo, ou no parâmetro deste modelo, não é permitido. O parâmetro deve respeitar o limite de 25 caracteres. Será obrigatório o uso de 1 botão no layout.",
              marketing: "Para uso em campanhas, promoções e ofertas; mensagens de boas-vindas e despedida; atualizações, convites ou recomendações; ou pedidos para responder ou completar uma nova transação.",
              marketingInfo: "Aumente suas chances de aprovação! Use botões de 'Cancelar inscrição' para permitir aos seus contatos cancelar o recebimento de mensagens de marketing sem bloquear seu número, e aumente as chances de aprovação do seu modelo.",
              utility: "Palavras como: Oferta, promoção, bônus, desconto, pesquisa não devem ser usadas, para que o modelo não seja considerado um contato de Marketing. Contextos de boas-vindas, despedidas, convites ou recomendações também devem ser evitados.",
              configuration: "Configurações",
              templateContent: "Conteúdo do Modelo",
              required: "é obrigatório.",
              invalid: "é inválido",
              typeOfOtp: {
                title: "Tipo de OTP",
                select: "Selecione um tipo",
                copyCode: "Cupom (Copiar código)",
                oneTap: "Preenchimento automático (Um toque)",
                otpInfo: "Sua mensagem pode conter apenas um botão e sem variáveis."
              },
              buttons: {
                save: "Salvar",
                cancel: "Cancelar",
                close: "Fechar",
                next: "Próximo",
                previous: "Anterior",
                add: "Adicionar",
                update: "Alterar"
              },
              securityAdvisory: "Adicionar aviso de segurança?",
              securityAdvisoryMessage: " Para sua segurança, não compartilhe este código.",
              addCodeExpirationTime: "Adicionar tempo de expiração do código?",
              otpMessage: "é seu código de verificação.",
              expirationTimeMessage: "Este código expira em {minutes} minutos.",
              buttonText: "Texto do botão",
              packageName: "Nome do pacote",
              applicationSignatureHash: "Hash da assinatura do aplicativo",
              messageBody: "Corpo da mensagem",
              addFooter: {
                title: "Rodapé (Sua mensagem pode conter um rodapé como texto).",
                without: "Sem rodapé",
                text: "texto"
              },
              footerText: "Texto do rodapé",
              headerOption: {
                title: "Cabeçalho (Sua mensagem pode conter um cabeçalho com texto destacado, imagem, documento ou vídeo).",
                without: "Sem cabeçalho",
                text: "Texto",
                image: "Imagem",
                document: "Documento",
                video: "Vídeo"
              },
              headerText: "Texto do cabeçalho",
              dragText: "Arraste um arquivo aqui ou clique no botão abaixo para selecionar.",
              selectFile: "Selecionar um arquivo",
              invalidFormatFile: "Um arquivo inválido foi selecionado. Os formatos aceitos são:",
              buttonsOption: {
                title: "Botão (Sua mensagem pode conter botões de diferentes tipos).",
                without: "Sem botões",
                text: "Com botões",
                type: "Tipo",
                typeText: "Digite seu texto",
                select: "Selecione um tipo",
                url: "URL",
                phoneNumber: "Número de telefone",
                quickReply: "Resposta rápida",
                optOut: "Cancelar inscrição"
              },
            },
            buttons: {
              add: "Criar Novo Modelo"
            }
          }
        }        
      },
      whatsappModal: {
        title: {
          add: "Adicionar Conexão",
          edit: "Editar Conexão",
        },
        form: {
          name: "Nome",
          default: "Padrão",
          option: {
            moduleTitle: "Canal de Atendimento",
            title: "Tipo de conexão",
            receptive: "Atendimento",
            massive: "Mensagens Massivas",
            receptivePhrase: "Atendimento: Exclusivo para atendimentos.",
            massivePhrase: "Mensagens Massivas: Para atendimentos, disparos de campanhas e envio de listas de transmissão."
          }
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      schedules: {
        title: "Agendamentos",
        table: {
          columns: {
            message: "Mensagem",
            date: "Data de envio",
            recurrence: "Recorrência",
            status: "Status",
            actions: "Ações"
          },
          buttons: {
            send: "Enviar imediatamente",
            edit: "Editar agendamento",
            delete: "Deletar agendamento",
          },
          status: {
            await: "Aguardando",
            started: "Iniciado",
            finished: "Finalizado",
            error: "Erro",
          },
        },
        noData: "Nenhuma informação foi encontrada.",
        scheduleBy: "Mensagem agendada por:"

      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        filters: {
          searchPlaceholder: "Pesquisar...",
          searchTooltip: "Pesquise pelo nome, número, empresa, e-mail ou cpf do contato.",
          connections: "Todas as conexões",
          users: "Todos os usuários",
          tags: "Filtrar etiquetas",
        },
        form: {
          name: "Nome",
          phoneNumber: "Número",
          connection: "Conexão",
          queue: "Fila",
          email: "E-mail",
          company: "Empresa",
          address: "Endereço",
          birth: "Data de Nascimento",
          rg: "RG",
          cpf: "CPF",
          acceptLgpd: "Aceite LGPD",
          lgpdAt: "Data Aceite LGPD",
          forAllUsers: "Para todos usuários?",
          status: "Status",
          selectStatus: "Selecionar status",
          origin: "Origem",
          selectOrigin: "Selecionar origem",
          productsAndServices: "Produto(s) / Serviço(s)",
          selectProductsAndServices: "Selecionar produto(s) / serviço(s)",
        },
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os atendimentos relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar contatos",
          add: "Adicionar contato",
          startConversation: "Iniciar conversa",
          newContact: "Novo contato",
          importContacts: "Importar contatos"
        },
        titles: {
          startConversation: "Iniciar conversa",
          exportContacts: "Exportar Contatos",
          edit: "Editar",
          delete: "Excluir"
        },
        table: {
          channel: "Canal",
          name: "Nome",
          origem: "Origem",
          email: "E-mail",
          company: "Empresa",
          createdBy: "Criador",
          connection: "Conexão",
          actions: "Ações",
        },
        errors: {
          queueNotFound: "Selecione uma fila."
        }
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          opportunity: "Oportunidade",
          comments: "Comentários",
          name: "Nome",
          number: "Número do whatsapp",
          email: "Email",
          cpf: "CPF",
          rg: "RG",
          dataNascimento: "Data de nascimento",
          endereco: "Endereço",
          extraName: "Nome do campo",
          extraValue: "Valor",
          opportunity: "Oportunidade",
          opportunityRecurringValue: "Valores Recorrentes",
          opportunitySingleValue: "Valores Únicos",
          opportunityContractTime: "Prazo Contratual (Em meses)",
          opportunityContractValue: "Valores dos Contratos",
          opportunityExpirationData: "Vencimento da Fatura",
          opportunityContractExpiration: "Vencimento do Contrato"
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar resposta rápida",
          edit: "Editar resposta rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta rápida salva com sucesso.",
      },
      tagModal: {
        title: {
          add: "Adicionar etiqueta",
          edit: "Editar etiqueta",
        },
        form: {
          name: "Nome",
          color: "Cor",
          status: "Status",
          keywords: "Palavras Chaves"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Etiqueta salva com sucesso.",
        error: "Erro ao salvar etiqueta.",
      },
      tagForContactsModal: {
        title: "Vincular Etiquetas",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
        success: "Vinculos salvos com sucesso.",
        error: "Erro ao salvar vinculo.",
        removePreviousTags: "Remover Etiqueta(s) Anteriores.",
      },
      openingHourModal: {
        title: {
          add: "Adicionar horário",
          edit: "Editar horário",
        },
        form: {
          name: "Nome",
          weekdays: "Dias da semana",
          hourStartName: "Horário de início",
          hourEndName: "Horário de finalização",
          holiday: "Evitar feriados",
          status: "Status",
          message: "Mensagem fora de horário",
          tabs: {
            holidays: "Não trabalhar nos feriados",
            linkQueues: "Vincular filas"
          },
          weekDays: {
            sun: "Domingo",
            mon: "Segunda-feira",
            tue: "Terça-feira",
            wed: "Quarta-feira",
            thu: "Quinta-feira",
            fry: "Sexta-feira",
            sat: "Sábado"
          }
        },
        invalidMessages: {
          name: {
            required: "Insira o nome.",
            max: "O campo de nome passou a quantidade de caracteres máximo.",
            invalid: "Este nome já foi utilizado para outro horário de atendimento."
          },
          message: {
            required: "Insira uma mensagem de retorno para seus clientes.",
            max: "O campo de mensagem passou a quantidade de caracteres máximo.",
          },
          weekDays: {
            required: "Insira os dias da semana em que estará disponível para atendimento.",
          },
          status: {
            required: "Erro ao validar status.",
          },
          deleted: "Horário de atendimento deletado com sucesso."
        },
        successMessages: {
          save: "Horário de atendimento salvo com sucesso.",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          selectAllHolidays: "Todos Feriados",
          selectAllQueues: "Todas Filas",
        },
        success: "horário salvo com sucesso.",
        error: "Erro ao salvar horário.",
      },
      quickConcludeMessage: {
        title: {
          add: "Adicionar mensagem de finalização",
          edit: "Editar mensagem de finalização",
        },
        form: {
          shortcut: "Atalho",
          message: "Mensagem de finalização",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          new: "Adicionar resposta de finalização",
        },
        list: {
          title: "Respostas de finalizações",
          shortcut: "Atalho",
          message: "Resposta de finalização",
          actions: "Ações",
        },
        errors: {
          alreadyExist: "Mensagem de finalização já existe.",
          companyIdNotFound: "Id da empresa não identificado.",
          shortcutIdNotFound: "Atalho não identificado",
          IdNotFound: "Id da mensagem de finalização não identificado.",
        },
        success: "Mensagem de finalização salva com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor da fila",
          greetingMessage: "Mensagem de saudação",
          token: "Token",
          public: "Fila pública?",
          distribution: {
            title: "Distribuição",
            automatic: "Distribuição automática",
            shared: "Caixa compartilhada"
          }
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        errors: {
          colorNotFound: "Selecione uma cor para fila."
        }
      },
      queueHistory: {
        title: 'Configurar Permissão de Acesso ao Histórico',
        info: 'Selecione abaixo, as filas das quais o histórico aparecerão no atendimento.'
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um contato para começar a conversar.",
        loadMore: "ver mais"
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      tickets: {
        toasts: {
          deleted: "A mensagem que você estava foi deletada.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Atendendo" },
          pending: { title: "Aguardando" },
          closed: { title: "Finalizados" },
          schedule: { title: "Agendamentos" },
          search: { title: "Busca" },
          managedBox: { title: "Caixa gestionada" },
          ura: { title: "URA" },
          chatbot: { title: "Chatbot" }
        },
        search: {
          placeholder: "Buscar contatos ou mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir atendimento",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldQueuePlaceholder: "Selecione uma fila",
        noOptions: "Nenhum usuário encontrado com esse nome.",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Em atendimento",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhuma mensagem ou contato encontrado com esse status ou termo pesquisado.",
        buttons: {
          accept: "Aceitar",
        },
      },
      ticketsSearch: {
        tooltip: "Configurações de pesquisa",
        title: "Opções de filtro",
        channel: "Canal",
        queues: "Fila(s) de Atendimento",
        tags: "Etiqueta(s)",
        noTags: "Sem Etiqueta(s)",
        selectAllTags: "Todas Etiqueta(s)",
        content: {
          title: "Conteúdo",
          contact: "Contato",
          message: "Mensagem"
        }
      },
      newTicketModal: {
        title: "Criar mensagem",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Iniciar",
          cancel: "Cancelar",
        },
      },
      messagesAPI: {
        title: 'API',
        textMessage: {
          number: 'Número',
          body: 'Mensagem',
          token: 'Token cadastrado',
        },
        mediaMessage: {
          number: 'Número',
          body: 'Nome do arquivo',
          media: 'Arquivo',
          token: 'Token cadastrado',
        }
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir fila",
          deleteMessage: "Deseja realmente excluir a fila",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      concludeMessage: {
        title: "Mensagens de finalização",
        table: {
          shortcut: "Atalho",
          message: "Mensagem de finalização",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar mensagem de finalização",
        },
        toasts: {
          deleted: "Mensagem de finalização excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta mensagem de finalização?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      quickAnswers: {
        title: "Respostas rápidas",
        filterDefault: "Todos usuários",
        table: {
          shortcut: "Atalho",
          message: "Resposta rápida",
          creator: "Criador",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar resposta rápida",
          file: "ARQUIVO",
          allUsers: "mostrar para todos usuários"
        },
        toasts: {
          deleted: "Resposta rápida excluída com sucesso.",
          copied: "Mensagem copiada com sucesso.",
          cannotCopied: "Arquivos não podem ser copiados.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta resposta rápida?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        contactMessenger: {
          send: "Enviar mensagem rapida",
          add: "Adicionar resposta rápida",
          userShortcuts: "Atalhos do usuário",
          systemShortcuts: "Atalhos do sistema",
        },
        errors: {
          alreadyExist: "Mensagem rápida já existe.",
          companyIdNotFound: "Id da empresa não identificado.",
          shortcutIdNotFound: "Atalho não identificado",
          messageOrFileNotFound: "Selecione um arquivo ou digite uma mensagem.",
          IdNotFound: "Id da mensagem rápida não identificado.",
        }
      },
      tags: {
        title: "Etiquetas",
        filterDefault: "Todos usuários",
        table: {
          name: "Nome",
          color: "Cor",
          status: "Status",
          updatedBy: "Último a editar",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar etiqueta",
        },
        toasts: {
          deleted: "Etiqueta deletada com sucesso.",
          saved: "Etiqueta salva com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir esta etiqueta?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        errors: {
          alreadyExist: "Etiqueta já existe.",
          companyIdNotFound: "Id da empresa não identificado.",
          IdNotFound: "Id da etiqueta não identificado.",
        }
      },
      openingHours: {
        title: "Horário de atendimento",
        filterDefault: "Todos usuários",
        table: {
          name: "Nome",
          start: "Horário de ínicio",
          end: "Horário de finalização",
          status: "Status",
          updatedBy: "Último a editar",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar horário",
        },
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir este horário?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        search: "Pesquisar...",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os atendimentos abertos deste usuário serão movidos para a fila.",
        },
        states: {
          online: "Online",
          out: "Ausente",
          offline: "Offline"
        }
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
            conclude: "Finalizar",
            autoMessage: "Mensagens Rápidas",
            contactInfo: "Informações do Contato",
            scheduleMessage: "Agendar Mensagem"
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem",
        placeholderClosed: "Reabra ou aceite essa mensagem para enviar uma mensagem.",
        placeholderConnection: "A conexão está indisponível para interagir.",
        signMessage: "Assinar",
        annotation: 'Anotação'
      },
      contactDrawer: {
        header: "Dados do contato",
        headerTicket: "Abrir Atendimento",
        headerTransfer: "Transferir Atendimento",
        headerConclude: "Finalizar Atendimento",
        headerFlashMessage: "Mensagens Rápidas",
        buttons: {
          edit: "Salvar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        scheduleMessage: "Agendar Mensagem",
        delete: "Deletar",
        transfer: "Transferir",
        transferToAwait: "Mover para Aguardando",
        linkTags: "Vincular Etiquetas",
        confirmationModal: {
          title: "Deletar o atendimento do contato",
          message:
            "Atenção! Todas as mensagens relacionadas ao atendimento serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        edit: "Editar",
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      ticketsByTags: {
        title: "Relatório de atendimentos por etiquetas",
        updated: "Atualizado em: ",
        ticketsByTags: "Atendimentos por Etiquetas",
        ticketsByTagsTotal: "Total: ",
        tabs: {
          open: "Atendendo",
          waitingForReturn: "Aguardando",
          closed: "Finalizado"
        },
        filters: {
          title: "Filtros",
          startDate: "Data de inicio",
          endDate: "Data de fim",
          tags: "Etiquetas",
          selectTags: "Selecione as etiquetas",
          connections: "Conexões",
          selectConnections: "Selecione as conexões",
          queues: "Filas",
          selectQueues: "Selecione as filas",
          selectAllTags: "Todas Etiqueta(s)",
          selectWithoutTags: "Sem etiqueta(s)",
          selectAllConnections: "Todas Conexões",
          selectAllQueues: "Todas Filas"
        },
        excelExport: {
          id: "Identificador",
          status: "Status",
          connectionName: "nomeConexão",
          connection: "numeroConexão",
          lastMessage: "ultimaMensagem",
          lastMessageDate: "dataUltimaMensagem",
          tag: "Etiqueta",
          queueName: "Fila",
          finishedAt: "dataFinalização",
          finishedBy: "finalizadoPor",
          finishedBy: "finalizadoPor",
          attendant: "Atendente",
          contactName: "contato",
          contactNumber: "numeroContato",
          conclusionMessage: "Finalização",
          lastReason: "motivoFinalização",
          isGroup: "Grupo",
          isChatbot: "Chatbot",
          translate: {
            isGroup: {
              yes: "sim",
              no: "não"
            },
            isChatbot: {
              yes: "sim",
              no: "não"
            },
            status: {
              open: "Atendendo",
              pending: "Aguardando",
              closed: "Finalizado"
            }
          }
        },
        buttons: {
          apply: "Aplicar",
          close: "Fechar",
          export: "Exportar"
        }
      },
      relatoryGeneralCampaigns: {
        title: "Relatório de campanhas",
        updatedAt: "atualizado em:",
        campaigns: {
          title: "Campanhas",
          total: "total de campanhas:",
          statusType: {
            started: "Em execução",
            paused: "Pausados",
            finished: "Finalizados",
            waiting: "Aguardando inicio",
          },
        },
        messages: {
          title: "Mensagens",
          subtitle: "total de mensagens enviadas por dia.",
          statusType: {
            answered: "Respondidos",
            open: "Aguardando envio",
            sent: "Enviados",
            error: "Erro ao enviar",
            blacklist: 'Bloqueados'
          },
        },
        history: {
          title: "Histórico",
          table: {
            name: "nome",
            begin: "inicio",
            end: "fim",
            days: "dias da semana",
            holiday: "feriados",
            status: "status",
            createdAt: "data de criação",
            moveTo: "mover para",
            totQty: "total de mensagens",
            sentQty: "mensagens enviadas",
            notSentQty: "mensagens não enviadas",
            errorQty: "mensagens com erros",
            numberConnection: "conexão",
            queue: "fila",
            action: "ações",
            statusType: {
              started: "Em execução",
              paused: "Pausado",
              finished: "Finalizado",
              waiting: "Aguardando inicio",
            },
            relatoryTitle: "Relatorio_Campanha"
          },
          buttons: {
            relatory: "relatório",
            message: "visualizar mensagem",
            export: "exportar",
            close: "fechar"
          }
        },
        filters: {
          title: "Filtros",
          startDate: "Data de inicio",
          endDate: "Data de fim",
          connections: "Conexões",
          selectConnections: "Selecione as conexões",
          queues: "Filas",
          selectQueues: "Selecione as filas",
        },
        buttons: {
          apply: "Aplicar",
          close: "Fechar"
        },
        errors: {
          invalid_interval_date: "Intervalo máximo de 1 mês."
        }
      },
      ack: {
        error: 'Erro ao enviar',
        pending: 'Pendente',
        sent: 'Enviada',
        serverAck: 'Entregue',
        read: "Lida",
        broadcast: "Via lista de transmissão",
        annotation: "Anotação",
        scheduling: "Agendada",
        schedulingBy: "Agendada Por:",
        isApi: "Api"
      },
      relatoryTicket: {
        status: {
          open: "Atendendo",
          closed: "Finalizado",
          pending: "Aguardando"
        }
      },
      weekDays: {
        sun: "Domingo",
        mon: "Segunda-Feira",
        tue: "Terça-Feira",
        wed: "Quarta-Feira",
        thu: "Quinta-Feira",
        fri: "Sexta-Feira",
        sat: "Sábado",
        today: "Hoje",
        yesterday: "Ontem"
      },
      databaseFound: {
        title: "Base de dados não encontrada",
        message: "Ocorreu um problema na identificação do banco de dados da sua empresa, entre em contato com o administrador.",
        button: "Inicio"
      },
      pageFound: {
        title: "Página não encontrada",
        message: "A página não existe no sistema.",
        button: "Inicio"
      },
      importContactsModal: {
        title: "Envie o arquivo com contatos para adicionar no sistema",
        loadMessage: "Validando contatos enviados...",
        errorMessage: {
          start: "Foram encontrados",
          end: "contatos com erro ou duplicados no sistema.",
          warning: "Corrija estes contatos baixando o arquivo clicando no botão abaixo.",
          button: "Baixe para corrigir"
        },
        uploadButton: "Envie o arquivo",
        downloadButton: "Download do Modelo",
        importButton: "Importar contatos",
        uploadingMessage: "Aguarde, carregando arquivo...",
        valid: "Validos:",
        invalid: "Invalidos:",
        duplicated: "Duplicados:",
        tabs: {
          main: "Dados Principais",
          tags: "Vincular Etiquetas"
        },
        form: {
          whatsapp: "Whatsapp vinculado",
          queue: "Fila",
          forAll: "Para todos usuários?"
        }
      },
      integrations: {
        title: "Integrações",
        subtitle: "Configure interações da plataforma de atendimento com outros sistemas.",
        api: {
          title: "API",
          table: {
            title: "titulo",
            name: "nome",
            status: "status",
            method: "método",
            actions: "ações",
            createdAt: "criado em:",
            updatedAt: "atualizado em:",
          },
          form: {
            create: "cadastrar api",
            update: "atualizar api",
            info: "informações da api",
            title: "titulo",
            name: "nome",
            method: "método",
            connection: "conexão",
            sendContactAnswer: "encaminhar resposta do contato",
            url: "url",
            headers: "cabeçalho",
            answerContact: "enviar uma resposta padrão para quando o usuário responder o envio?",
            answerContactPlaceholder: "escreva sua mensagem...",
            validations: {
              titleRequired: "Selecione um nome",
              titleMin: "O titulo digitado é curto",
              nameRequired: "Selecione um nome",
              nameMin: "O nome selecionado é curto",
              methodRequired: "Selecione um método",
              methodMin: "O método selecionado é curto",
              connectionRequired: "Selecione uma conexão",
              urlRequired: "Digite uma url",
              urlMin: "A url informada é curta",
              success: "Salvo com sucesso",
              deleted: "Deletado com sucesso"
            }
          },
          buttons: {
            add: "adicionar api",
            cancel: "cancelar",
            save: "salvar"
          }
        },
        webhook: {
          title: "WEBHOOK",
          table: {
            name: "nome",
            url: "url",
            status: "status",
            method: "método",
            edit: "editar"
          },
          form: {
            create: "cadastrar webhook",
            update: "atualizar webhook",
            name: "nome",
            method: "método",
            url: "url",
            auth: {
              option: "autenticação",
              user: "usuário",
              password: "senha"
            },
            queryString: "querystring",
            json: "json",
            capture: "capturas",
            headers: "cabeçalho",
            fields: "campos",
            validations: {
              nameRequired: "Selecione um nome",
              nameMin: "O nome selecionado é curto",
              methodRequired: "Selecione um método",
              methodMin: "O método selecionado é curto",
              urlRequired: "Digite uma url",
              urlMin: "A url informada é curta",
              authOptionRequired: "Selecione uma autenticação",
              authOptionMin: "A autenticação informada é curta",
              authUserRequired: "Digite um usuário de autenticação",
              authUserMin: "Usuário de autenticação é curto",
              authPasswordRequired: "Digite uma senha de autenticação",
              authPasswordMin: "Senha de autenticação é curto",
              success: "Salvo com sucesso",
              deleted: "Deletado com sucesso"
            },
            customFields: {
              key: {
                title: "chave",
                required: "Digite uma chave",
                min: "O valor de chave é muito curto",
                added: "chave já adicionada"
              },
              value: {
                title: "valor",
                required: "Digite um valor",
                min: "O valor é muito curto"
              },
              message: {
                title: "Posição de retorno",
                required: "Digite uma posição de retorno",
              },
            },
          },
          buttons: {
            add: "adicionar webhook",
            cancel: "cancelar",
            save: "salvar"
          }
        },
      },
      batchReopen: {
        title: "Abrir Atendimento",
        whatsapp: "Selecione uma conexão",
        queue: "Selecione uma fila",
        buttons: {
          cancel: "Cancelar",
          confirm: "Confirmar"
        }
      },
      menu: {
        list: {
          ticket: "Atendimento",
          contact: "Contato",
          dashboard: "Dashboard",
          report: {
            title: "Relatório",
            ticketsRelatory: "Atendimento",
            tagsRelatory: "Atendimento por Etiqueta",
            generalCampaignsRelatory: "Campanha"
          },
          campaign: "Campanha",
          admin: {
            title: "Configurações",
            connection: "Conexão",
            queue: "Fila",
            quickAnswer: "Mensagem Rápida",
            conclusionMessage: "Finalização",
            openingHour: "Horário de Atendimento",
            tag: "Etiqueta",
            user: "Usuário",
            integration: "Integração",
            chatbot: "Chatbot",
            customerRelationship: "CRM"
          },
          adminSuper: {
            company: "Empresa",
            component: "Componentes"
          }
        },
        profile: {
          changePassword: "alterar senha",
          changeProfile: "alterar perfil",
          logout: "sair"
        }
      },
      component: {
        title: "Componentes",
        table: {
          name: "Nome",
          description: "Descrição",
          status: "Status",
          createdAt: "Criado Em:"
        },
        byCompanyModal: {
          title: "Gerenciar acesso aos componentes do sistema",
          cancel: "Cancelar",
          save: "Salvar"
        },
        messages: {
          updated: "Componente atualizado com sucesso.",
          updatedLinks: "Componentes atualizados com sucesso."
        }
      },
      changePassword: {
        success: "Senha alterada com sucesso.",
        modal: {
          title: "Alterar Senha",
          password: "Senha",
          newPassword: "Nova Senha",
          confirmPassword: "Confirmar Senha",
          newPasswordAlerts: {
            title: "Nível de segurança da senha - A nova senha deve conter: ",
            qty: "8 Caracteres;",
            uppercase: "1 Letra Maiúscula;",
            lowercase: "1 Letra Minúscula;",
            special: "1 Caractere Especial;",
            number: "1 Caractere Numérico."
          }
        },
        buttons: {
          add: "Adicionar",
          cancel: "Cancelar"
        }
      },
      customerRelationship: {
        title: "CRM",
        configuration: {
          title: "Configurações CRM",
          origin: {
            title: "Origem",
            table: {
              name: "Nome",
              status: "Status",
              editedBy: "Último a editar",
              actions: "Ações"
            },
            form: {
              name: "Nome",
              type: "Tipo",
              min: "Mínimo",
              max: "Máximo",
              text: "Texto",
              number: "Númerico Inteiro",
              decimalNumber: "Númerico Decimal",
              date: "Data",
              minInfo: "Quantidade mínima de caracteres para texto, valor mínimo para númericos ou data minima.",
              maxInfo: "Quantidade máxima de caracteres para texto, valor máximo para númericos ou data máxima."
            },
            messages: {
              nameRequired: "Insira o nome do campo",
              typeRequired: "Insira o tipo do campo",
            },
            delete: {
              title: "Deletar origem",
              description: "Deseja realmente delete este campo de origem?"
            }
          },
          productAndService: {
            title: "Produto(s) / Serviço(s)",
            table: {
              name: "Nome",
              status: "Status",
              editedBy: "Último a editar",
              actions: "Ações"
            },
            form: {
              name: "Nome",
              type: "Tipo",
              min: "Mínimo",
              max: "Máximo",
              text: "Texto",
              number: "Númerico Inteiro",
              decimalNumber: "Númerico Decimal",
              date: "Data",
              minInfo: "Quantidade mínima de caracteres para texto, valor mínimo para númericos ou data minima.",
              maxInfo: "Quantidade máxima de caracteres para texto, valor máximo para númericos ou data máxima."
            },
            messages: {
              nameRequired: "Insira o nome do campo",
              typeRequired: "Insira o tipo do campo",
            },
            delete: {
              title: "Deletar produto/serviço",
              description: "Deseja realmente delete este campo de produto/serviço?"
            }
          },
          status: {
            title: "Status",
            table: {
              name: "Nome",
              status: "Status",
              editedBy: "Último a editar",
              actions: "Ações",
              order: "Ordem"
            },
            form: {
              name: "Nome",
            },
            messages: {
              nameRequired: "Insira o nome do campo",
            },
            delete: {
              title: "Deletar status",
              description: "Deseja realmente delete este campo de status?"
            }
          },
        },
        buttons: {
          configuration: "Configuração"
        }
      },
      forms: {
        min: "é muito curto.",
        max: "é muito longo.",
        invalid: "é inválido.",
        required: "é obrigatório."
      },
      buttons: {
        edit: "Editar",
        delete: "Excluir",
        save: "Salvar",
        copy: "Copiar",
        add: "Adicionar",
        info: "Informação",
        close: "Fechar",
        cancel: "Cancelar"
      },
      groupPrefix: "GRUPO | ",
      selectAll: "Selecionar todos",
      allSelected: "Todos Selecionados",
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND: "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED: "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT: "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA: "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_WAPP_MAX_REACHED: "Você atingiu o número máximos de conexões permitidas em seu plano. Entre em contato com o suporte para saber mais",
        ERR_INVALID_CREDENTIALS: "Erro de autenticação. Por favor, tente novamente.",
        ERR_INVALID_COMPANY: "Erro de autenticação. Desculpe, seu login foi inativado.",
        ERR_SENDING_WAPP_MSG: "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um Atendimento aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre novamente.",
        ERR_USER_CREATION_DISABLED: "A criação do usuário foi desabilitada pelo administrador.",
        ERR_USER_MAX_REACHED: "Você atingiu o número máximos de usuários permitidos em seu plano. Entre em contato com o suporte para saber mais.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhuma mensagem encontrada com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar Mensagem no banco de dados.",
        ERR_FETCH_WAPP_MSG: "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED: "A mensagem de saudação é obrigatório quando há mais de uma fila.",
        ERR_TAG_INVALID_COMPANYID: "Id da empresa não identificado.",
        ERR_TAG_INVALID_NAME: "Nome para etiqueta inválido.",
        ERR_TAG_INVALID_COLOR: "Cor para etiqueta inválida.",
        ERR_TAG_INVALID_UPDATEDBY: "Id do usuário não identificado.",
        ERR_TAG_INVALID_STATUS: "Status para etiqueta inválido.",
        ERR_TAG_COLOR_ALREADY_EXISTS: "Já existe uma etiqueta com essa cor.",
        ERR_TAG_NAME_ALREADY_EXISTS: "Já existe uma etiqueta com esse nome.",
        ERR_TAG_MAX_EXCEEDED: "Quantidade de etiquetas ativas excedidas.",
        ERR_CONCLUDE_FOUND: "Selecione uma opção de finalização.",
        ERR_NO_COMPANY_FOUND: "Empresa não identificada.",
        ERR_NO_CAMPAIGN_FOUND: "Campanha não identificada.",
        ERR_NO_CONTACTNUMBER_FOUND: "Número de contato inválido/não identificado.",
        ERR_DATABASE_FOUND: "Base de dados não encontrada.",
        ERR_INTEGRATION_ALREADY_EXISTS: "Esta integração já foi cadastrada.",
        ERR_USER_EMAIL_ALREADY_EXISTS: "Já existe um usuário cadastrado com este endereço de e-mail.",
      },
      backendSuccess: {
        SAVED_SUCCESSFULLY: "Salvo com sucesso.",
        DELETED_SUCCESSFULLY: "Deletado com sucesso.",
      }
    },
  },
};

export { messages };

