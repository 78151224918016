import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    makeStyles,
    Divider,
    Tab,
    Tabs
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Box, DialogContent, Grid, IconButton, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { i18n } from "../../../translate/i18n";
import integrationNames from "./integrationNames.json";
import integrationMethods from "./integrationMethods.json";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { withStyles } from '@material-ui/core/styles';
import IntegrationHeaderTab from "../Tabs/Headers";
import * as Yup from "yup";
import toastError from "../../../errors/toastError";
import { useContext } from "react";
import { AuthContext } from "../../../context/Auth/AuthContext";


const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        maxWidth: '500px'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textTagContainer: {
        width: "100%",
    },
    tabs: {
        background: 'transparent',
        color: '#000'
    }
}));

const ApiInfoModal = ({ open, onClose, integrationId }) => {
    const classes = useStyles();
    const initialState = {
        id: null,
        name: integrationNames['api'][0],
        url: '',
        method: integrationMethods['api'][0],
        body: 'JSON',
        type: 'api',
        auth: { option: 'NOAUTH', user: '', password: '' },
        headers: [
            { key: "Access-Control-Allow-Origin", value: "*" },
            { key: "Access-Control-Allow-Headers", value: "Authorization" },
        ],
        fields: []
    };
    const [integration, setIntegration] = useState(initialState);
    const [company, setCompany] = useState(null);
    const { user } = useContext(AuthContext);
    const array = [
        { key: "messageText", value: "Texto a ser enviado para a API, ou legenda para arquivos, ou titulo para localizações (Opcional)." },
        { key: "messageUrl", value: "Url do arquivo a ser enviado (Opcional)." },
        { key: "number", value: "Número com o DDI e DDD do número a ser enviado (Obrigatório)." },
        { key: "lat", value: "Latitude da localização, necessário longitude e endereço (Opcional)." },
        { key: "log", value: "Longitude da localização, necessário latitude e endereço (Opcional)." },
        { key: "address", value: "Endereço da localização, necessário longitude e latitude (Opcional)." }
      ];

    useEffect(() => {
        if (open == false || !integrationId || isNaN(integrationId)) return;

        // Função para recuperar suas conexões
        (async () => {
            try {
                const { data: integrationData } = await api.get(`/integrations/${integrationId}`, { params: { encryptConnectionId: true } });
                setIntegration(integrationData);

                const { data: companyData } = await api.get(`/company/${user.adminId}`);
                setCompany(companyData);
            } catch (error) {
                console.error({ err: error });
                toast.error('Erro ao resgatar conexões, contate o administrador.');
            }
        })()
    }, [open]);


    // Handlers
    const handleClose = () => {
        setIntegration(initialState);
        setCompany(null);
        onClose();
    };

    const ArrayToJsonView = ({ array }) => {
        // Transforma o array em um objeto
        const jsonObject = array.reduce((acc, item) => {
            acc[item.key] = item.value || "";
            return acc;
        }, {});

        // Converte o objeto para uma string JSON formatada com quebras de linha
        const formattedJson = JSON.stringify(jsonObject, null, 4); // '4' define o espaçamento para melhor legibilidade

        return (
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                {formattedJson}
            </pre>
        );
    };

    // Renderização
    return (
        <div className={classes.root}>
            <Dialog 
                open={open == true ? true : false}
                onClose={handleClose} 
                maxWidth="md" 
                fullWidth 
                scroll="paper" 
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
                    {i18n.t(`integrations.api.form.info`)}
                </DialogTitle>
                <DialogContent>

                    {/* nome*/}
                    <Grid container spacing={2}>
                        <Grid item xs={12} mb={2}>
                            <Typography
                                component="label"
                                style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    marginRight: "10px",
                                    color: "#424242"
                                }}
                            >
                                Endpoint:
                            </Typography>
                            {`${process.env.REACT_APP_BACKEND_URL.endsWith('/') ? process.env.REACT_APP_BACKEND_URL : process.env.REACT_APP_BACKEND_URL + '/'}api/v1/SEND_MESSAGES`}

                        </Grid>
                    </Grid>
                    {/* método */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} mb={2}>
                            <Typography
                                component="label"
                                style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    marginRight: "10px",
                                    color: "#424242"
                                }}
                            >
                                Method:
                            </Typography>
                            {integration?.method || ''}
                        </Grid>
                    </Grid>
                    {/* token */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} mb={2}>
                            <Typography
                                component="label"
                                style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    marginRight: "10px",
                                    color: "#424242"
                                }}
                            >
                                token:
                            </Typography>
                            {company?.token || ''}
                        </Grid>
                    </Grid>
                    {/* connectionId */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} mb={2}>
                            <Typography
                                component="label"
                                style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    marginRight: "10px",
                                    color: "#424242"
                                }}
                            >
                                connectionToken:
                            </Typography>
                            {integration?.fields?.find(field => field.key == 'connectionId')?.value || ''}
                        </Grid>
                    </Grid>
                    {/* Body params */}
                    <Divider />
                    <Grid container spacing={2}>
                        <Grid item xs={12} mb={2}>
                            <Typography
                                component="label"
                                style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    marginRight: "10px",
                                    color: "#424242"
                                }}
                            >
                                Body Params:
                            </Typography>
                            <ArrayToJsonView array={array} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        {i18n.t(`buttons.close`)}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ApiInfoModal;